import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

// User
import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
import Page404 from '../views/Page404.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Forms from '../views/Forms.vue'
import FormsEdit from '../views/FormsEdit.vue'
import FormsSend from '../views/FormsSend.vue'
import FormsStats from '../views/FormsStats.vue'
import CreatePassword from '../views/CreatePassword.vue'
import FormsAnswers from '../views/FormAnswer.vue'

//Admin
import Companies from '../views/admin/Companies.vue'
import CompaniesEdit from '../views/admin/CompaniesEdit.vue'
import Users from '../views/admin/Users.vue'
import UsersEdit from '../views/admin/UsersEdit.vue'
import AdminQuestions from '../views/admin/AdminQuestions.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: { layout: 'login' },
        component: ForgotPassword
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        meta: { layout: 'login' },
        component: ResetPassword
    },
    {
        path: '/create-password/:token',
        name: 'create-password',
        meta: { layout: 'login' },
        component: CreatePassword
    },
    {
        path: '/mon-compte',
        name: 'account',
        component: Account
    },
    {
        path: '/login',
        name: 'login',
        meta: { layout: 'login' },
        component: Login
    },
    {
        path: '/formulaires/:id/statistiques',
        name: 'forms.stats',
        component: FormsStats
    },
    {
        path: '/formulaires/:id/reponses',
        name: 'forms.answers',
        component: FormsAnswers
    },
    {
        path: '/formulaires/:id/envoyer',
        name: 'forms.send',
        component: FormsSend
    },
    {
        path: '/formulaires/:id',
        name: 'forms.edit',
        component: FormsEdit
    },
    {
        path: '/formulaires',
        name: 'forms',
        component: Forms
    },
    {
        path: '/admin/entreprises/:id',
        name: 'companies.edit',
        component: CompaniesEdit
    },
    {
        path: '/admin/entreprises',
        name: 'companies',
        component: Companies
    },
    {
        path: '/admin/utilisateurs/:id',
        name: 'users.edit',
        component: UsersEdit
    },
    {
        path: '/admin/utilisateurs',
        name: 'users',
        component: Users
    },
    {
        path: '/admin/questions',
        name: 'admin_questions',
        component: AdminQuestions
    },
    {
        path: '/*',
        name: 'notfound',
        meta: { layout: 'login' },
        component: Page404
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

/*router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
          next();
          return;
      }
      if( to && to.path ) {
          next('/login?from=' + to.path);
      } else {
          next('/login');
      }
  } else {
      next();
  }
});*/

export default router
