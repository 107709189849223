var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-5",attrs:{"fluid":""}},[_c('h1',[_vm._v(" Utilisateur ")]),_c('v-snackbar',{attrs:{"right":"","color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('h3',[_vm._v(" Infos ")]),(_vm.error_save && _vm.error_save.errors)?_c('v-alert',{attrs:{"color":"red","type":"error"}},_vm._l((_vm.error_save.errors),function(err,index){return _c('div',{key:'err_'+index},[_vm._l((err),function(e){return [_vm._v(" "+_vm._s(e)+" ")]})],2)}),0):_vm._e(),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Prénom*","required":""},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('v-text-field',{attrs:{"label":"Nom*","required":""},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('v-text-field',{attrs:{"label":"Email*","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-select',{attrs:{"items":[{
                                name: 'Utilisateur',
                                value: 'user'
                            },{
                                name: 'Administrateur',
                                value: 'admin'
                            }],"item-text":"name","item-value":"value","label":"Rôle*","required":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('v-select',{attrs:{"items":_vm.companies,"item-text":"name","item-value":"id","label":"Entreprise*","clearable":true,"required":""},model:{value:(_vm.user.company_id),callback:function ($$v) {_vm.$set(_vm.user, "company_id", $$v)},expression:"user.company_id"}}),_c('v-btn',{staticClass:"mr-1",attrs:{"type":"submit","color":"primary","loading":_vm.loadingSave,"disabled":_vm.loadingSave},on:{"click":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_vm._v(" Enregistrer ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }