<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1>
            Entreprises
        </h1>

        <v-dialog v-model="modal_create" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Créer une entreprise</h3>
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="saveCompany">
                        <v-text-field
                            v-model="company_name"
                            label="Nom de l'entreprise"
                            required
                        ></v-text-field>
                    </v-form>
                    <div class="text-center">
                        <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveCompany" :loading="loadingCreate" :disabled="loadingCreate">
                            Enregistrer
                        </v-btn>
                        <v-btn color="darken-1" class="ml-1" text @click="modal_create=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal_delete" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Supprimer une entreprise</h3>
                </v-card-title>
                <v-card-text>
                    <div>
                        <p>
                            Êtes-vous sûr de vouloir supprimer l'entreprise <strong>{{ company_to_delete.name }}</strong> ?<br>
                            Cela va supprimer tous les comptes, formulaires et données de l'entreprise, de façon définitive.
                        </p>
                        <v-btn type="submit" color="red" :dark="!loadingDelete" class="mr-1" @click.prevent="deleteCompany" :loading="loadingDelete" :disabled="loadingDelete">
                            Supprimer
                        </v-btn>
                        <v-btn color="darken-1" class="ml-1" text @click="modal_delete=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        
        <v-form @submit.prevent="searchCompany">
            <v-row align="center">
                <v-col cols="8" sm="4" md="4">
                    <v-text-field
                        v-model="search"
                        label="Rechercher"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="4" sm="2" md="2">
                    <v-btn type="submit" color="white" depressed>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                    <v-btn color="primary" depressed @click.prevent="modal_create = true">
                        <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Nouvelle entreprise
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
                
        <v-data-table
            :headers="headers"
            :items="companies"
            :options.sync="options"
            :server-items-length="totalCompanies"
            :loading="loading"
            :no-data-text="'Aucune entreprise trouvée'"
            class="elevation-0"
            :footer-props="{
                'items-per-page-text': 'Résultats par page',
                'items-per-page-options': [10,20,50,100]
            }"
            fluid
        >
            <template v-slot:item.name="{ item }">
                <div class="d-flex align-center">
                    <img :src="item.logourl" v-if="item.logourl" class="accountPic accountPic--small mr-2"> <span class="">{{ item.name }}</span>
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <router-link :to="{ name: 'companies.edit', params:{id: item.id} }" class="text-decoration-none mr-3">
                    <v-icon color="primary">mdi-circle-edit-outline</v-icon> éditer
                </router-link>
                <a href="#" class="text-decoration-none red--text" @click.prevent="confirmDelete(item)">
                    <v-icon color="red">mdi-close-circle-outline</v-icon> suppr.
                </a>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            companies: [],
            totalCompanies: 0,
            search: '',
            options: {
                page: 1,
                itemsPerPage: 20,
                sortBy: ['id'],
                sortDesc: [true],
            },
            headers: [
                { text: 'ID', value: 'id', sortable: true, },
                { text: 'Nom', value: 'name', sortable: true, },
                { text: 'Utilisateurs', value: 'users_count', sortable: false, },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],
            loadingCreate: false,
            modal_create: false,
            company_name: '',
            company_to_delete: false,
            modal_delete: false,
            loadingDelete: false,
        }
    },
    created() {
        //
    },
     methods: {
        getCompanies() {            
            // build api url
            var api_url = process.env.VUE_APP_API + '/admin/companies?page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage;

            if (this.options.sortBy.length === 1) {
                api_url = api_url + '&sortBy=' + this.options.sortBy[0];
            }

            if(this.options.sortDesc.length === 1) {
                if( this.options.sortDesc[0] ) {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=DESC';
                } else {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=ASC';
                }
            }

            if( this.search && this.search.length > 0 ) {
                api_url = api_url + '&search=' + this.search;
            }

            this.loading = true;
            this.$http
                .get(api_url)
                .then(response => {
                    this.companies = response.data.data;
                    this.totalCompanies = response.data.meta.total;
                    this.options.page = response.data.meta.current_page;
                    this.options.itemsPerPage = response.data.meta.per_page;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        searchCompany() {
            // go to paginate 1
            this.options.page = 1;
            // search results
            this.getCompanies();
        },
        saveCompany() {
            this.loadingCreate = true;
            this.$http
                .post(process.env.VUE_APP_API + '/admin/companies', {
                    name: this.company_name
                })
                .then( response => {
                    this.loadingCreate = false;
                    this.modal_create = false;
                    // redirect to edit page
                    this.$router.push({
                        name: 'companies.edit',
                        params: {
                            id: response.data.company.id,
                        },
                        query: {
                            created: 1
                        }
                    });
                    //this.getCompanies();
                }).catch( () => {
                    this.loadingCreate = false;
                });
        },
        confirmDelete(company) {
            this.company_to_delete = company;
            this.modal_delete = true;
        },
        deleteCompany() {
            if( this.company_to_delete && this.company_to_delete.id ) {
                this.loadingDelete = true;
                this.$http
                    .delete(process.env.VUE_APP_API + '/admin/companies/' + this.company_to_delete.id)
                    .then(() => {
                        this.loadingDelete = false;
                        this.modal_delete = false;
                        this.company_to_delete = false;
                        this.snackbarText = "Entreprise supprimée";
                        this.snackbarColor = 'success';
                        this.snackbar = true;

                        // reload companies
                        this.getCompanies();
                    }).catch( () => {
                        this.loadingDelete = false;
                    });
            }
        }
    },
    watch: {
        options: {
            handler () {
                this.getCompanies();
            },
            deep: true,
        },
    },
    components: {
    }
}
</script>
