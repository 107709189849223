<template>
    <div class="login">
        <div class="login-header text-center mb-4">
            IFTH
        </div>
        <v-card class="elevation-4 mt-5 pt-2 pb-4">
            <v-card-text>
                <div v-if="loading">
                    <Loading />
                </div>
                <div v-else>
                    <v-form @submit.prevent="resetPass" v-if="tokenOk">
                        <h2 class="mb-3">Bienvenue sur IFTH</h2>
                        <div class="ma-3" v-if="message && message.length > 0">
                            <div v-if="success">
                                <v-alert type="success">
                                    {{ message }}
                                </v-alert>
                                <div class="text-center">
                                    <router-link class="d-block w-100" :to="{ name: 'login' }">
                                        Accéder à la page de connexion
                                    </router-link>
                                </div>
                            </div>
                            <v-alert type="error" v-else>
                                {{ message }}
                            </v-alert>
                        </div>
                        <div v-if="! success">
                            <div class="form-group">
                                <v-text-field  
                                    required 
                                    type="password"
                                    label="Définissez votre mot de passe*" 
                                    id="password" 
                                    v-model="password" 
                                    :readonly="loading" 
                                />
                            </div>
                            <div class="form-group">
                                <v-text-field  
                                    required 
                                    type="password"
                                    label="Confirmez votre mot de passe*" 
                                    id="paconfirm_passwordssword" 
                                    v-model="confirm_password" 
                                    :readonly="loading" 
                                />
                            </div>
                            <div v-if="loading">
                                <div class="btn btn-primary btn-lg d-block w-100 text-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Initialisation ...</span>
                                    </div>
                                </div>
                            </div>
                            <v-btn v-else 
                                type="submit" 
                                color="primary" 
                                depressed
                            >
                                Définir mon mot de passe
                            </v-btn>
                        </div>
                    </v-form>
                    <div v-else>
                        <v-alert type="error">
                            Ce lien est incorrect. Veuillez nous contacter pour obtenir un nouveau lien.<br>
                        </v-alert>
                        <div class="text-center">
                            <router-link :to="{ name: 'forgot-password' }">
                                Ou essayez de réinitialiser votre mot de passe
                            </router-link>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';

    export default {
        data(){
            return {
                loading: true,
                tokenOk: false,
                password : "",
                confirm_password : "",
                message: null,
                success: false,
            }
        },
        created() {
            this.controlToken();
        },
        methods: {
            controlToken() {
                this.loading = true;
                this.$http
                    .post(process.env.VUE_APP_API + '/auth/token', {
                        token: this.$route.params.token,
                    })
                    .then( () => {
                        this.tokenOk = true;
                        this.loading = false;
                    }).catch( () => {
                        this.tokenOk = false;
                        this.loading = false;
                    });
            },
            resetPass() {
                this.loading = true;
                this.message = null;
                this.success = false;
                this.$http
                .post(process.env.VUE_APP_API + '/auth/reset-password', {
                    token: this.$route.params.token,
                    password: this.password,
                    confirm_password: this.confirm_password,
                    create_password: 1
                })
                .then( () => {
                    this.message = "Votre mot de passe a bien initialisé. Vous pouvez maintenant vous connecter.";
                    this.loading = false;
                    this.success = true;
                }).catch( err => {
                    this.message = err.response.data.message;
                    this.loading = false;
                });
            }
        },
        components: {
            Loading,
        }
    }
</script>