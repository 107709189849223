<template>
    <v-container
        class="pb-5"
        fluid
    >
        <v-row class="mb-0" v-if="form">
            <v-col class="mb-0 pb-0">
                <small>
                    <router-link class="primary--text text-decoration-none" :to="{ name: 'forms.stats', params:{id: form.id} }">
                        <v-icon small color="blue">mdi-arrow-left</v-icon> Retour au statistiques
                    </router-link>
                </small>
            </v-col>
        </v-row>
        <v-row class="mb-0" v-if="form">
            <v-col cols="4">
                <h2 class="mb-0">
                    Détails des réponses - <strong>{{ form.title }}</strong><br>
                </h2>
            </v-col>
            <v-col class="text-right" cols="8">
                <v-btn color="primary" outlined @click.prevent="downloadAnswers" class="mb-2" :loading="loadingCSV">
                    <v-icon small color="blue">mdi-download</v-icon> Télécharger
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="loadingAnswers">
            <v-col class="text-center pt-5">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else>
            <template v-if="enablelisting">
                <v-col lg="4" md="4" sm="5" cols="12">
                    <h3 class="mb-2">RÉPONDANTS</h3>
                    <v-card :elevation="1" class="rounded-lg">
                        <v-simple-table>
                            <tbody>
                            <tr v-for="answer in form.answers" :key="'tester_'+answer.tester" style="cursor:pointer" @click="selectTester(answer)" :class="{selected: selected_tester === answer.tester}">
                                <td>
                                    {{ answer.tester }}
                                </td>
                                <td class="text-right">
                                    <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <v-col>
                    <h3 class="mb-2">RÉPONSES</h3>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Question
                                </th>
                                <th class="text-left">
                                    Réponse
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="answers && answers.length > 0">
                                <tr
                                    v-for="(ans, index) in answers"
                                    :key="'ans_'+index"
                                >
                                    <td>
                                        <template v-if="ans && ans.title">
                                            {{ ans.title }}
                                        </template>
                                    </td>
                                    <td>
                                        <span v-for="a in ans.answers" :key="'a_'+a.id" class="mr-3">
                                            <template v-if="a.title">
                                                {{ a.title }}
                                            </template>
                                            <template v-else-if="a.comment">
                                                {{ a.comment }}
                                            </template>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="2" align="center">
                                        <em>N'a pas encore répondu au formulaire</em>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </template>
            <template v-else>
                <v-col class="text-center pt-5">
                    <p class="pt-5">
                        <em>Les réponses ne sont pas disponibles.</em>
                    </p>
                    <p>
                        <router-link class="primary--text text-decoration-none" :to="{ name: 'forms.stats', params:{id: this.$route.params.id} }">
                            <v-icon small color="blue">mdi-arrow-left</v-icon> Retour au statistiques
                        </router-link>
                    </p>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import downloadCSV from '@/functions/downloadCSV.js';

export default {
    data() {
        return {
            loadingAnswers: false,
            form: false,
            selected_tester: false,
            answers: [],
            loadinganswers: false,
            enablelisting: true,
            loadingCSV: false,
        }
    },
    created() {
        this.getStats();
    },
    methods: {
        getStats() {
            this.loadingAnswers = true;
            this.$http
                .get(process.env.VUE_APP_API + '/forms/' + this.$route.params.id + '/answers' )
                .then(response => {
                    this.form = response.data.data;
                    if( this.form.answers && this.form.answers[0] ) {
                        this.selected_tester = this.form.answers[0].tester;
                        this.answers = this.form.answers[0].answers;
                    }
                    this.loadingAnswers = false;
                }).catch( () => {
                    this.loadingAnswers = false;
                    this.enablelisting = false;
                });
        },
        selectTester(answer) {
            this.selected_tester = answer.tester;
            this.answers = answer.answers;
        },
        downloadAnswers() {
            this.loadingCSV = true;
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.$route.params.id + '/answers/download', {}, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadCSV(response.data, 'reponses');
                    this.loadingCSV = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingCSV = false;
                });
        }
    }
}
</script>
