<template>
    <div class="login">
        <div class="text-center">
            <img class="img-fluid" width="300" src="@/assets/images/logo-ifth.png" alt="IFTH">
        </div>

        <v-card class="elevation-4 mt-5 pa-5 ifthcard rounded-xl">
            <v-card-text>
                <v-row>
                    <v-col class="ifth-imglogin rounded-xl mr-5"></v-col>
                    <v-col>
                        <h2 class="mb-1">
                            Plateforme mevaho : méthode de validation humaine On-line
                        </h2>
                        <div class="mb-3">
                            <em>par IFTH</em>
                        </div>
                        
                        <p>
                            Bienvenue sur notre plateforme d’enquête Mévaho (méthode de validation humaine en ligne).
                        </p>
                        <p>
                            Elle vous permettra de réaliser des questionnaires sensoriels propres à vos besoins de sondage et d’obtenir ainsi une analyse des perceptions liées à l’usage de vos produits, auprès des testeurs que vous avez identifiés. Elle est intuitive et agile et peut également vous permettre de développer tous types de questionnaires en fonction de vos attentes.
                        </p>
                        <p>
                            Nous sommes disponibles et à votre écoute pour faire évoluer cet outil à votre convenance et vous permettre d’en découvrir toutes les fonctionnalités.
                        </p>
                        <p>
                            Connectez-vous pour accéder à votre tableau de bord et vos questionnaires en attente ou déjà crées.
                        </p>
                        <v-form @submit.prevent="login">
                            <div v-if="$store.state.status == 'error'">
                                <v-alert type="error">
                                    Email ou mot de passe incorrect
                                </v-alert>
                            </div>

                            <v-text-field outlined dense label="Email" name="email" type="email" v-model="email" :readonly="$store.state.status == 'loading'" />
                            <v-text-field outlined dense id="password" label="Mot de passe" name="password" type="password" v-model="password" :readonly="$store.state.status == 'loading'" />

                            <div class="d-flex justify-space-between align-center">
                                <v-btn type="submit" large color="primary" :loading="$store.state.status == 'loading'" :disabled="$store.state.status == 'loading'">Connexion</v-btn>
                                <router-link :to="{ name: 'forgot-password' }" class="">
                                    <small>Mot de passe perdu</small>
                                </router-link>
                            </div>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                email : "",
                password : ""
            }
        },
        methods: {
            login: function () {
                let email = this.email;
                let password = this.password;
                this.$store.dispatch('login', { email, password })
                    .then(() => {
                        if( this.$route.query.from ) {
                            this.$router.push(this.$route.query.from);
                        } else {
                            this.$router.push('/');
                        }
                    })
                    .catch( () => {
                        //console.log(err) 
                    })
            }
        }
    }
</script>