import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
/*import VueCookies from 'vue-cookies';
import createMutationsSharer from "vuex-shared-mutations";*/

Vue.use(Vuex);
//Vue.use(VueCookies);

export default new Vuex.Store({
    //plugins: [createMutationsSharer({ predicate: ['refresh_success'] })],
    state: {
        status: '',
        token: '',
        user: {},
        loggedIn: false,
    },
    getters : {
        isLoggedIn: function(state) {
          if( state.token.length > 0 ) {
              //return true;
              return false;
          }
          return false;
      },
        authStatus: state => state.status,
        user: state => state.user,
    },
    mutations: {
        auth_request(state){
            state.status = 'loading';
        },
        auth_success(state, data) {
            state.token = data.token;
            state.loggedIn = true;
            state.status = 'success';
        },
        auth_error(state) {
            state.status = 'error';
            state.user = {};
            state.loggedIn = false;
        },
        logout(state) {
            state.token = '';
            state.loggedIn = false;
            state.status = '';
        },
        refresh_success(state, data) {
            // define token as token
            
            // define state
            state.status = 'success';
            state.token = data.token;
            state.user = data.user;
            state.loggedIn = true;

        },
        update_user_data(state, data) {
            state.user = data;
        }
    },
    actions: {
        login({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios.get(process.env.VUE_APP_API + '/sanctum/csrf-cookie').then( () => {
                  axios.post(process.env.VUE_APP_API + '/auth/login', {
                    email: user.email,
                    password: user.password,
                    device_name: 'browser'
                  }).then( resp => {
                    let returned_date = {
                        token: resp.data.token,
                        user: resp.data.user
                    }
                    //Vue.$cookies.set('cooltraining_user_token', returned_date.token, -1);
                    //axios.defaults.headers.common['Authorization'] = 'Bearer ' + returned_date.token;
                    commit('auth_success', returned_date);
                    commit('update_user_data', returned_date.user);
                    resolve(resp);
                  }).catch(err => {
                    commit('auth_error');
                    //Vue.$cookies.remove('cooltraining_user_token');
                    reject(err);
                  });
                });

                
                
            })
        },
        register({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({url: process.env.VUE_APP_API + '/auth/register', data: user, method: 'POST' })
                    .then(resp => {
                        let returned_data = {
                            token: resp.data.token,
                            user: resp.data.user
                        }
                        //Vue.$cookies.set('cooltraining_user_token', returned_data.token, -1);
                        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + returned_data.token;
                        commit('auth_success', returned_data);
                        commit('update_user_data', returned_data.user);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('auth_error', err);
                        //Vue.$cookies.remove('cooltraining_user_token');
                        reject(err);
                    })
            })
        },
        logout({commit}){
            return new Promise((resolve/*, reject*/) => {
                commit('logout');
                // logout from Laravel API
                axios({url: process.env.VUE_APP_API + '/auth/logout', data: null, method: 'POST' })
                    .then( () => {
                        //Vue.$cookies.remove('cooltraining_user_token');
                        //delete axios.defaults.headers.common['Authorization'];
                        resolve();
                    })
                    .catch( () => {
                        //console.log(err);
                    });
                resolve();
            })
        },
        /*refresh({commit}) {
            return new Promise((resolve/*, reject* /) => {
                commit('auth_request');
                axios({url: process.env.VUE_APP_API + '/auth/refresh', data: null, method: 'POST' })
                    .then(resp => {
                        let returned_date = {
                            token: resp.data, // get new token from API
                            //user: resp.data.user
                        }
                        commit('refresh_success', returned_date);
                    })
                    .catch( () => {
                        //console.log(err);
                    });
                resolve();
            })
        },*/
        update_user({commit}, user) {
            commit('update_user_data', user);
        }
    }
})
