<template>
    <div class="login">
        <div class="text-center">
            <!-- <img class="img-fluid" width="180" src="@/assets/images/logo-cooltraining-blanc.png" alt="Cooltraining"> -->
        </div>

        <v-card class="elevation-4 mt-5 pt-2 pb-4">
            <v-card-text>
                <h2 class="mb-3 text-center">Mot de passe perdu</h2>
                <p>
                    Saisissez votre adresse mail, vous recevrez un lien pour réinitialiser votre mot de passe.
                </p>
                
                <v-alert type="success" v-if="message">
                    Si votre adresse mail correspond à un compte, vous recevrez un email pour réinitialiser votre mot de passe.
                </v-alert>
                <v-form @submit.prevent="resetPass" v-else>
                    <v-text-field label="Email" name="email" type="email" v-model="email" :readonly="loading" />
                    <v-card-actions class="justify-center">
                        <v-btn type="submit" color="primary" depressed :loading="loading" :disabled="loading">Réinitialiser mon mot de passe</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>

        <div class="text-center mt-4">
            <router-link :to="{ name: 'login' }" class="white--text">
                <small>Retour à l'écran de connexion</small>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                loading: false,
                email : '',
                password : '',
                message: false,
            }
        },
        methods: {
            resetPass() {
                this.loading = true;
                this.$http
                    .post(process.env.VUE_APP_API + '/auth/lost-password', {
                        email: this.email,
                    })
                    .then( () => {
                        this.message = true;
                        this.loading = false;
                    }).catch( () => {
                        this.message = true;
                        this.loading = false;
                    });
            }
        }
    }
</script>