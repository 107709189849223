<template>
    <div class="defaultLayout">
        <Menu :drawer="true" />
        
        <v-main>
            <!-- slot content -->
            <slot />
        </v-main>

    </div>
</template>

<script>
    import Menu from '@/components/Menu';

    export default {
        components: {
            Menu,
        },
        data(){
            return {
                drawer: null,
                //displayUpdate: false,
            }
        },
        created() {
        },
        mounted () {
        },
        beforeDestroy () {
        },
        methods: {
        }
    }
</script>