<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1>
            Utilisateur
        </h1>

        <v-snackbar
            v-model="snackbar"
            right
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>
        
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <h3>
                            Infos
                        </h3>
                        <v-alert color="red" type="error" v-if="error_save && error_save.errors">
                            <div v-for="(err, index) in error_save.errors" :key="'err_'+index">
                                <template v-for="e in err">
                                    {{ e }}
                                </template>
                            </div>
                        </v-alert>
                        <v-form @submit.prevent="saveUser">
                            <v-text-field
                                v-model="user.firstname"
                                label="Prénom*"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.lastname"
                                label="Nom*"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="user.email"
                                label="Email*"
                                required
                            ></v-text-field>
                            <v-select
                                v-model="user.role"
                                :items="[{
                                    name: 'Utilisateur',
                                    value: 'user'
                                },{
                                    name: 'Administrateur',
                                    value: 'admin'
                                }]"
                                item-text="name"
                                item-value="value"
                                label="Rôle*"
                                required
                            ></v-select>
                            <v-select
                                :items="companies"
                                item-text="name"
                                item-value="id"
                                v-model="user.company_id"
                                label="Entreprise*"
                                :clearable="true"
                                required
                            ></v-select>
                            <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveUser" :loading="loadingSave" :disabled="loadingSave">
                                Enregistrer
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            loadingSave: false,
            loadingCompanies: false,
            loadingPic: false,
            user: false,
            error_save: false,
            companies: [],
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
        }
    },
    created() {
        this.getUser()
        this.getCompanies()
    },
     methods: {
        getUser() {            
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/admin/users/' + this.$route.params.id )
                .then(response => {
                    this.user = response.data.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getCompanies() {            
            this.loadingCompanies = true;
            this.$http
                .get(process.env.VUE_APP_API + '/admin/companies?all=1' )
                .then(response => {
                    this.companies = response.data.data;
                    this.loadingCompanies = false;
                }).catch( () => {
                    this.loadingCompanies = false;
                });
        },
        saveUser() {
            this.loadingSave = true;
            this.error_save = false;
            this.$http
                .put(process.env.VUE_APP_API + '/admin/users/' + this.$route.params.id, {
                    firstname: this.user.firstname,
                    lastname: this.user.lastname,
                    email: this.user.email,
                    role: this.user.role,
                    company_id: this.user.company_id,
                })
                .then( () => {
                    this.loadingSave = false;
                    // display success
                    this.snackbarText = "Utilisateur mis à jour";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( error => {
                    this.error_save = error.response.data;
                    this.loadingSave = false;
                });
        }
    },
    watch: {
    },
    components: {
    }
}
</script>
