<template>
    <div class="text-center pt-4 white--text">
        <h1>404</h1>
        <p>
            Vous êtes perdu ?
        </p>
        <p>
            <u>
                <router-link :to="{ name: 'home' }" class="btn btn-primary white--text">Retour à l'accueil</router-link>
            </u>
        </p>
    </div>
</template>

<script>
    export default {
    }
</script>
