<template>
    <div class="login">
        <div class="text-center">
            <!-- <img class="img-fluid" width="180" src="@/assets/images/logo-cooltraining-blanc.png" alt="Cooltraining"> -->
        </div>

        <Loading v-if="loading" />
        <v-card class="elevation-4 mt-5 pt-2 pb-4" v-else>
            <v-card-text v-if="tokenOk">
                <h2 class="mb-3 text-center">Mot de passe perdu</h2>
                <p>
                    Saisissez votre nouveau mot de passe.
                </p>
                
                <v-form @submit.prevent="resetPass">
                    <div class="ma-3" v-if="message && message.length > 0">
                        <div v-if="success">
                            <v-alert type="success">
                                {{ message }}
                            </v-alert>
                            <div class="text-center">
                                <v-btn :to="{ name: 'login' }" color="primary" outlined>
                                    Retour à l'écran de connexion
                                </v-btn>
                            </div>
                        </div>
                        <v-alert type="error" v-else>
                            {{ message }}
                        </v-alert>
                    </div>

                    <div v-if="! success">
                        <v-text-field
                            v-model="new_password"
                            label="Nouveau mot de passe*"
                            type="password"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="confirm_password"
                            label="Confirmez le nouveau mot de passe*"
                            type="password"
                            required
                        ></v-text-field>

                        <v-card-actions class="justify-center">
                            <v-btn type="submit" color="primary" depressed :loading="loadingPass" :disabled="loadingPass">Réinitialiser mon mot de passe</v-btn>
                        </v-card-actions>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-text v-else>
                <v-alert type="error">
                    Ce lien est incorrect.
                </v-alert>
            </v-card-text>
        </v-card>

        <div class="text-center mt-4">
            <router-link :to="{ name: 'login' }" class="white--text">
                <small>Retour à l'écran de connexion</small>
            </router-link>
        </div>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    export default {
        data(){
            return {
                loading: false,
                tokenOk: false,
                loadingPass: false,
                new_password : '',
                confirm_password : '',
                message: false,
                succes: false
            }
        },
        created() {
            this.controlToken();
        },
        methods: {
            controlToken() {
                this.loading = true;
                this.$http
                    .post(process.env.VUE_APP_API + '/auth/token', {
                        token: this.$route.params.token,
                    })
                    .then( () => {
                        this.tokenOk = true;
                        this.loading = false;
                    }).catch( () => {
                        this.tokenOk = false;
                        this.loading = false;
                    });
            },
            resetPass() {
                this.loadingPass = true;
                this.message = null;
                this.success = false;
                this.$http
                .post(process.env.VUE_APP_API + '/auth/reset-password', {
                    token: this.$route.params.token,
                    password: this.new_password,
                    confirm_password: this.confirm_password,
                })
                .then( () => {
                    this.message = "Votre mot de passe a bien été modifié. Vous pouvez maintenant vous connecter.";
                    this.loadingPass = false;
                    this.success = true;
                }).catch( err => {
                    this.message = err.response.data.message;
                    this.loadingPass = false;
                });
            }
        },
        components: {
            Loading
        }
    }
</script>