<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1>
            Mon compte
        </h1>

        <v-snackbar
            v-model="snackbar"
            right
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>
        
        <Loading v-if="loadingAccount" />
        <div v-else>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-text>
                            <h3>
                                Photo de profil
                            </h3>
                            <div v-if="errorsPic">
                                <v-alert color="red" type="error" v-if="errorsPic.errors">
                                    <div v-for="(err, index) in errorsPic.errors" :key="index">
                                        <template v-for="e in err">
                                            {{ e }}
                                        </template>
                                    </div>
                                </v-alert>
                            </div>
                            
                            <div class="accountPic" v-if="user && user.picurl" :style="'background-image:url('+user.picurl+')'"></div>
                            <div class="accountPic" v-else></div>

                            <template v-if="user && user.picurl">
                                <div class="text-center mt-3 mb-3">
                                    <v-btn small color="red" @click.prevent="deletePic" :loading="loadingDeletePic" :disabled="loadingDeletePic">
                                        Suppr. la photo
                                    </v-btn>
                                </div>
                            </template>
                            <div class="form-group">
                                <label for="pic" v-if="user && user.picurl">
                                    Modifier votre photo de profil :
                                </label>
                                <label class="pic" v-else>
                                    Choisissez une photo de profil :
                                </label>
                                <v-file-input
                                    accept="image/*"
                                    label="Photo"
                                    @change="handleFileUpload"
                                ></v-file-input>
                            </div>
                            <div class="form-group">
                                <v-btn color="primary" @click.prevent="changeProfilPic" depressed :disabled="! pic || pic.length == 0 || loadingPic" :loading="loadingPic">
                                    Enregistrer
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-text>
                            <h3>
                                Mes infos
                            </h3>
                            <v-alert color="red" type="error" v-if="error_save && error_save.errors">
                                <div v-for="(err, index) in error_save.errors" :key="'err_'+index">
                                    <template v-for="e in err">
                                        {{ e }}
                                    </template>
                                </div>
                            </v-alert>
                            <v-form @submit.prevent="saveUser">
                                <v-text-field
                                    v-model="user.firstname"
                                    label="Prénom*"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="user.lastname"
                                    label="Nom*"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="user.email"
                                    label="Email*"
                                    required
                                ></v-text-field>
                                <v-btn type="submit" depressed color="primary" class="mr-1" @click.prevent="saveUser" :loading="loadingSave" :disabled="loadingSave">
                                    Enregistrer
                                </v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-text>
                            <h3>
                                Modifier mon mot de passe
                            </h3>
                            <v-alert color="red" type="error" v-if="error_pass && error_pass.errors">
                                <div v-for="(err, index) in error_pass.errors" :key="'err_'+index">
                                    <template v-for="e in err">
                                        {{ e }}
                                    </template>
                                </div>
                            </v-alert>
                            <v-form @submit.prevent="changePassword">
                                <v-text-field
                                    v-model="current_password"
                                    label="Mot de passe actuel*"
                                    type="password"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="new_password"
                                    label="Nouveau mot de passe*"
                                    type="password"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="confirm_password"
                                    label="Confirmez le nouveau mot de passe*"
                                    type="password"
                                    required
                                ></v-text-field>
                                <v-btn type="submit" depressed color="primary" class="mr-1" @click.prevent="changePassword" :loading="loadingPass" :disabled="loadingPass">
                                    Enregistrer
                                </v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loadingAccount: false,
            loadingPic: false,
            loadingDeletePic: false,
            user: false,
            errorsPic: false,
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            pic: false,
            error_save: false,
            loadingSave: false,

            current_password: '',
            new_password: '',
            confirm_password: '',
            loadingPass: false,
            error_pass: false,
        }
    },
    created() {
        this.getAccount();
    },
     methods: {
        getAccount() {
            this.loadingAccount = true;
            let api_url = process.env.VUE_APP_API + '/auth/me';
            this.$http
                .get(api_url)
                .then(response => {
                    this.user = response.data.user;
                    this.loadingAccount = false;
                }).catch( () => {
                    this.loadingAccount = false;
                });
        },
        handleFileUpload(e) {
            this.pic = e;
        },
        changeProfilPic() {
            this.loadingPic = true;
            this.errorsPic = null;
            let formData = new FormData();
            formData.append('pic', this.pic);
            this.$http
                .post(process.env.VUE_APP_API + '/auth/pic', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.loadingPic = false;
                    this.snackbarText = "Photo mise à jour";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    this.pic = '';

                    // reload user data
                    this.getAccount();

                }).catch( error => {
                    this.errorsPic = error.response.data;
                    this.loadingPic = false;
                });
        },
        deletePic() {
            this.loadingDeletePic = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/auth/pic')
                .then(() => {
                    this.loadingDeletePic = false;
                    this.snackbarText = "Photo supprimée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload user data
                    this.getAccount();

                }).catch( () => {
                    this.loadingDeletePic = false;
                });
        },
        saveUser() {
            this.loadingSave = true;
            this.error_save = false;
            this.$http
                .put(process.env.VUE_APP_API + '/auth/me', {
                    firstname: this.user.firstname,
                    lastname: this.user.lastname,
                    email: this.user.email,
                })
                .then( () => {
                    this.loadingSave = false;
                    // display success
                    this.snackbarText = "Infos enregistrées";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( error => {
                    this.error_save = error.response.data;
                    this.loadingSave = false;
                });
        },
        changePassword() {
            this.loadingPass = true;
            this.error_pass = null;
            this.$http
                .post(process.env.VUE_APP_API + '/auth/me/password', {
                    current_password: this.current_password,
                    new_password: this.new_password,
                    confirm_password: this.confirm_password
                })
                .then(() => {
                    this.loadingPass = false;
                    // display success
                    this.snackbarText = "Nouveau mot de passe enregistré";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.current_password = '';
                    this.new_password = '';
                    this.confirm_password = '';
                }).catch(error => {
                    this.error_pass = error.response.data;
                    this.loadingPass = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>