<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1>
            Utilisateurs
        </h1>

        <v-dialog v-model="modal_create" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Créer un utilisateur</h3>
                </v-card-title>
                <v-card-text>
                    <v-alert color="red" type="error" v-if="error_save && error_save.errors">
                        <div v-for="(err, index) in error_save.errors" :key="'err_'+index">
                            <template v-for="e in err">
                                {{ e }}
                            </template>
                        </div>
                    </v-alert>
                    <v-form @submit.prevent="saveUser">
                        <v-text-field
                            v-model="firstname"
                            label="Prénom*"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="lastname"
                            label="Nom*"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            label="Email*"
                            required
                        ></v-text-field>
                        <v-select
                            v-model="role"
                            :items="[{
                                name: 'Utilisateur',
                                value: 'user'
                            },{
                                name: 'Administrateur',
                                value: 'admin'
                            }]"
                            item-text="name"
                            item-value="value"
                            label="Rôle*"
                            required
                        ></v-select>
                        <v-select
                            :items="companies"
                            item-text="name"
                            item-value="id"
                            v-model="company_id"
                            label="Entreprise*"
                            required
                        ></v-select>
                    </v-form>
                    <div class="text-center">
                        <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveUser" :loading="loadingCreate" :disabled="loadingCreate">
                            Enregistrer
                        </v-btn>
                        <v-btn color="darken-1" class="ml-1" text @click="modal_create=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal_delete" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Supprimer un utilisateur</h3>
                </v-card-title>
                <v-card-text>
                    <div>
                        <p>
                            Êtes-vous sûr de vouloir supprimer l'utilisateur <strong>{{ user_to_delete.firstname }} {{ user_to_delete.lastname }}</strong> ?<br>
                            Cela va supprimer tous ses formulaires et données associées, de façon définitive.
                        </p>
                        <v-btn type="submit" color="red" :dark="!loadingDelete" class="mr-1" @click.prevent="deleteUser" :loading="loadingDelete" :disabled="loadingDelete">
                            Supprimer
                        </v-btn>
                        <v-btn color="darken-1" class="ml-1" text @click="modal_delete=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        
       
        <v-form @submit.prevent="searchUser">
            <v-row align="center" >
                <v-col cols="8" sm="4" md="4">
                    <v-text-field
                        v-model="search"
                        label="Rechercher"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="4" sm="2" md="2">
                    <v-btn type="submit" color="white" depressed>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                    <v-btn color="primary" depressed @click.prevent="modal_create = true">
                        <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Nouveau compte
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

         <v-card :elevation="0">
            <v-data-table
                :headers="headers"
                :items="users"
                :options.sync="options"
                :server-items-length="totalUsers"
                :loading="loading"
                :no-data-text="'Aucun utilisateur trouvé'"
                class="elevation-0"
                :footer-props="{
                    'items-per-page-text': 'Résultats par page',
                    'items-per-page-options': [10,20,50,100]
                }"
                fluid
            >
                <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center">
                        <img :src="item.picurl" v-if="item.picurl" class="accountPic accountPic--small mr-2"> <span class="">{{ item.firstname }} {{ item.lastname }}</span>
                    </div>
                </template>
                <template v-slot:item.company="{ item }">
                    <template v-if="item.company && item.company.name">{{ item.company.name }}</template>
                </template>
                <template v-slot:item.role="{ item }">
                    <template v-if="item.role && item.role === 'admin'">
                        Administrateur
                    </template>
                    <template v-else>
                        Utilisateur
                    </template>
                </template>
                <template v-slot:item.actions="{ item }">
                    <router-link :to="{ name: 'users.edit', params:{id: item.id} }" class="text-decoration-none mr-3">
                        <v-icon color="primary">mdi-circle-edit-outline</v-icon> éditer
                    </router-link>
                    <a href="#" class="text-decoration-none red--text" @click.prevent="confirmDelete(item)">
                        <v-icon color="red">mdi-close-circle-outline</v-icon> suppr.
                    </a>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            loadingCompanies: false,
            loadingCreate: false,
            users: [],
            totalUsers: 0,
            search: '',
            options: {
                page: 1,
                itemsPerPage: 20,
                sortBy: ['id'],
                sortDesc: [true],
            },
            headers: [
                { text: 'ID', value: 'id', sortable: true, },
                { text: 'Nom', value: 'name', sortable: true, },
                { text: 'Email', value: 'email', sortable: true, },
                { text: 'Entreprise', value: 'company', sortable: false, },
                { text: 'Rôle', value: 'role', sortable: true, },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],
            modal_create: false,
            firstname: '',
            lastname: '',
            email: '',
            role: 'user',
            companies: [],
            company_id: '',
            error_save: false,
            user_to_delete: false,
            modal_delete: false,
            loadingDelete: false,
        }
    },
    created() {
        this.getCompanies();
    },
    methods: {
        getUsers() {
            // build api url
            var api_url = process.env.VUE_APP_API + '/admin/users?page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage;

            if (this.options.sortBy.length === 1) {
                api_url = api_url + '&sortBy=' + this.options.sortBy[0];
            }

            if(this.options.sortDesc.length === 1) {
                if( this.options.sortDesc[0] ) {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=DESC';
                } else {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=ASC';
                }
            }

            if( this.search && this.search.length > 0 ) {
                api_url = api_url + '&search=' + this.search;
            }

            this.loading = true;
            this.$http
                .get(api_url)
                .then(response => {
                    this.users = response.data.data;
                    this.totalUsers = response.data.meta.total;
                    this.options.page = response.data.meta.current_page;
                    this.options.itemsPerPage = response.data.meta.per_page;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        searchUser() {
            // go to paginate 1
            this.options.page = 1;
            // search results
            this.getUsers();
        },
        getCompanies() {            
            this.loadingCompanies = true;
            this.$http
                .get(process.env.VUE_APP_API + '/admin/companies?all=1' )
                .then(response => {
                    this.companies = response.data.data;
                    this.loadingCompanies = false;
                }).catch( () => {
                    this.loadingCompanies = false;
                });
        },
        saveUser() {
            this.loadingCreate = true;
            this.error_save = false;
            this.$http
                .post(process.env.VUE_APP_API + '/admin/users', {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    role: this.role,
                    company_id: this.company_id
                })
                .then( response => {
                    this.loadingCreate = false;
                    this.modal_create = false;
                    // redirect to user edit
                    this.$router.push({
                        name: 'users.edit',
                        params: {
                            id: response.data.user.id,
                        }
                    });
                }).catch( error => {
                    this.error_save = error.response.data;
                    this.loadingCreate = false;
                });
        },
        confirmDelete(user) {
            this.user_to_delete = user;
            this.modal_delete = true;
        },
        deleteUser() {
            if( this.user_to_delete && this.user_to_delete.id ) {
                this.loadingDelete = true;
                this.$http
                    .delete(process.env.VUE_APP_API + '/admin/users/' + this.user_to_delete.id)
                    .then(() => {
                        this.loadingDelete = false;
                        this.modal_delete = false;
                        this.user_to_delete = false;
                        this.snackbarText = "Utilisateur supprimé";
                        this.snackbarColor = 'success';
                        this.snackbar = true;

                        // reload users
                        this.getUsers();
                    }).catch( () => {
                        this.loadingDelete = false;
                    });
            }
        }
    },
    watch: {
        options: {
            handler () {
                this.getUsers();
            },
            deep: true,
        },
    },
    components: {
    }
}
</script>
