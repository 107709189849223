<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1 class="mb-3">
            Gestion des questions
        </h1>

        <v-dialog v-model="modal_create_category" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Créer une catégorie</h3>
                </v-card-title>
                <v-card-text>
                    <v-alert color="red" type="error" v-if="error_save && error_save.errors">
                        <div v-for="(err, index) in error_save.errors" :key="'err_'+index">
                            <template v-for="e in err">
                                {{ e }}
                            </template>
                        </div>
                    </v-alert>
                    <v-form @submit.prevent="saveCategory">
                        <v-text-field
                            v-model="category_title"
                            label="Nom*"
                            required
                        ></v-text-field>
                        <v-textarea
                            v-model="category_description"
                            label="Description"
                        ></v-textarea>
                        <v-textarea
                            v-model="category_descriptionfront"
                            label="Description pour les testeurs"
                        ></v-textarea>
                    </v-form>
                    <div class="text-center">
                        <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveCategory" :loading="loadingCreateCategory" :disabled="loadingCreateCategory">
                            Enregistrer
                        </v-btn>
                        <v-btn color="darken-1" class="ml-1" text @click="modal_create_category=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="modal_delete_question" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Supprimer la question</h3>
                </v-card-title>
                <v-card-text class="text-center">
                    Êtes-vous sûr de vouloir supprimer la question :<br><strong>{{ question_to_delete.title }}</strong><br><br>
                    Cette action est définitive.
                    <div class="text-center mt-4">
                        <v-btn color="red" dark class="mr-1" @click.prevent="deleteQuestion" :loading="loadingDeleteQuestion" :disabled="loadingDeleteQuestion">
                            Supprimer
                        </v-btn>
                        <v-btn color="darken-1" class="ml-1" text @click="modal_delete_question=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    
        <v-row>
            <v-col lg="4" md="4" sm="5" cols="12">
                <v-row class="mb-0">
                    <v-col>
                        <h2>Catégories</h2>
                    </v-col>
                    <v-col class="text-right">
                        <a href="#" @click.prevent="modal_create_category = true">
                            <v-icon color="primary" class="mr-1">mdi-plus-circle-outline</v-icon>Créer une catégorie
                        </a>
                    </v-col>
                </v-row>

                <v-simple-table class="elevation-1">
                    <tbody>
                        <tr v-for="category in categories" :key="'main_'+category.id" style="cursor:pointer" @click="selectCategory(category)" :class="{selected: selected_category.id === category.id}">
                            <td>
                                {{ category.title }} ({{ category.questions_count }})
                            </td>
                            <td class="text-right">
                                <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col>
                <v-row class="mb-0">
                    <v-col>
                        <h2>Questions</h2>
                    </v-col>
                    <v-col class="text-right">
                        <a href="#" @click.prevent="newQuestion">
                            <v-icon color="primary" class="mr-1">mdi-plus-circle-outline</v-icon>Créer une question
                        </a>
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="questions"
                    :options.sync="options"
                    :server-items-length="totalQuestions"
                    :loading="loadingQuestions"
                    :no-data-text="'Aucune question trouvée'"
                    class="elevation-0"
                    :footer-props="{
                        'items-per-page-text': 'Résultats par page',
                        'items-per-page-options': [10,20,50,100]
                    }"
                    fluid
                >
                    <template v-slot:item.title="{ item }">
                        {{ item.title }} <v-chip color="darkgrey" class="ml-2" dark small><em>{{ item.type }}</em></v-chip>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-icon color="green" v-if="item.status === 'active'">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <a @click.prevent="editQuestion(item)" class="mr-3">
                            <v-icon color="primary">mdi-circle-edit-outline</v-icon> éditer
                        </a>
                        <a @click.prevent="confirmDeleteQuestion(item)" v-if="item.formelements_count === 0">
                            <v-icon color="red">mdi-close-circle-outline</v-icon> <span class="red--text">suppr.</span>
                        </a>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-navigation-drawer
            v-model="panel_create_question"
            absolute
            temporary
            right
            style="width:90%;max-width:700px"
            >
            <v-form @submit.prevent="saveQuestion" class="pa-5">
                <h2 class="mb-4 font-weight-normal">
                    <template v-if="question && question.id">
                        Éditer la question
                    </template>
                    <template v-else>
                        Créer une question
                    </template>
                </h2>
                <v-alert color="red" type="error" v-if="error_save_question && error_save_question.errors">
                    <div v-for="(err, index) in error_save_question.errors" :key="'err_'+index">
                        <template v-for="e in err">
                            {{ e }}
                        </template>
                    </div>
                </v-alert>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="question.title"
                            label="Nom*"
                            required
                        ></v-text-field>
                        <v-textarea
                            v-model="question.description"
                            label="Description"
                        ></v-textarea>
                        <v-textarea
                            v-model="question.descriptionfront"
                            label="Description pour les testeurs"
                        ></v-textarea>
                        <v-alert v-if="! can_edit_type" dense outlined type="info">
                            Vous ne pouvez pas modifier le type de cette question car elle est déjà utilisée dans un formulaire.
                        </v-alert>
                        <v-select
                            :disabled="! can_edit_type"
                            v-model="question.type"
                            :items="[{
                                name: 'Note de 0 à 5',
                                value: 'note'
                            },{
                                name: 'Oui/Non',
                                value: 'oui_non'
                            },{
                                name: 'Échelle',
                                value: 'echelle'
                            },{
                                name: 'Texte libre',
                                value: 'text'
                            },{
                                name: 'Multiple',
                                value: 'multiple'
                            },{
                                name: 'Personnalisée',
                                value: 'custom'
                            }]"
                            item-text="name"
                            item-value="value"
                            label="Type de question*"
                            required
                            @change="displayAnswersFields($event)"
                        ></v-select>
                        <div v-if="question.type && question.type.length > 0 && question.type !== 'text'">
                            <v-alert text color="primary">
                                <div class="row">
                                    <div class="col" v-for="(a, index) in question.answers" :key="'answer_'+question.type+'_'+index">
                                        <div>
                                            <template v-if="question.type === 'echelle' || question.type === 'custom' || question.type === 'multiple'">
                                                <v-text-field
                                                    label="Label"
                                                    v-model="a.title"
                                                ></v-text-field>
                                            </template>
                                            <template v-else>
                                                {{ a.title }}
                                            </template>
                                        </div>
                                        <div>
                                            <v-text-field
                                                label="Nb de points"
                                                v-model="a.points"
                                            ></v-text-field>
                                        </div>
                                        <div v-if="(question.type === 'custom' || question.type === 'multiple') && can_edit_type">
                                            <a href="#" @click.prevent="deleteAnswer(question, index)">
                                                Suppr.
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col" v-if="(question.type === 'custom' || question.type === 'multiple') && can_edit_type">
                                        <a href="#" @click.prevent="addAnswer(question)">
                                            Ajouter
                                        </a>
                                    </div>
                                </div>
                            </v-alert>
                        </div>
                        <v-select
                            v-model="question.access"
                            :items="[{
                                name: 'Public',
                                value: 'public'
                            },{
                                name: 'Privée',
                                value: 'private'
                            }]"
                            item-text="name"
                            item-value="value"
                            label="Accès*"
                            required
                        ></v-select>
                        <div v-if="question.access === 'private'">
                            <v-select
                                v-model="question.companies"
                                :items="companies"
                                item-text="name"
                                item-value="id"
                                label="Choisissez les entreprises y ayant accès"
                                multiple
                                chips
                                persistent-hint
                            ></v-select>
                        </div>
                        <v-select
                            v-model="question.status"
                            :items="[{
                                name: 'Activée',
                                value: 'active'
                            },{
                                name: 'Désactivée',
                                value: 'disable'
                            }]"
                            item-text="name"
                            item-value="value"
                            label="Statut*"
                            required
                        ></v-select>
                        <v-select
                            :items="categories"
                            item-text="title"
                            item-value="id"
                            v-model="question.category_id"
                            label="Catégorie*"
                            required
                        ></v-select>
                        <div class="text-center">
                            <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveQuestion" :loading="loadingCreateQuestion" :disabled="loadingCreateQuestion">
                                Enregistrer
                            </v-btn>
                            <v-btn color="darken-1" class="ml-1" text @click="panel_create_question=false">Fermer</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loadingCategories: false,
            loadingQuestions: false,
            categories: [],
            selected_category: false,
            questions: [],
            totalQuestions: 0,
            options: {
                page: 1,
                itemsPerPage: 20,
                sortBy: ['id'],
                sortDesc: [true],
            },
            headers: [
                { text: 'N°', value: 'id', sortable: true, },
                { text: 'Status', value: 'status', sortable: true, },
                { text: 'Intitulé', value: 'title', sortable: true, },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],

            loadingCreateCategory: false,
            modal_create_category: false,
            category_title: '',
            category_description: '',
            category_descriptionfront: '',
            error_save: false,

            panel_create_question: false,
            error_save_question: false,
            question: {
                id: false,
                title: '',
                description: '',
                descriptionfront: '',
                type: '',
                access: 'public',
                status: 'active',
                category_id: '',
                companies: [],
                answers: []
            },
            loadingCreateQuestion: false,

            companies: false,
            
            can_edit_type: true,

            modal_delete_question: false,
            loadingDeleteQuestion: false,
            question_to_delete: false
        }
    },
    created() {
        this.getCategories();
        this.getCompanies();
    },
     methods: {
        getCategories() {            
            var api_url = process.env.VUE_APP_API + '/admin/categories';
            this.loadingCategories = true;
            this.loadingQuestions = true;
            this.$http
                .get(api_url)
                .then(response => {
                    this.categories = response.data.data;
                    if( this.categories && this.categories[0] ) {
                        this.selected_category = this.categories[0];
                        this.getQuestions();
                    }
                    this.loadingCategories = false;
                }).catch( () => {
                    this.loadingCategories = false;
                });
        },
        getCompanies() {
            this.$http
                .get(process.env.VUE_APP_API + '/admin/companies?all=1' )
                .then(response => {
                    this.companies = response.data.data;
                }).catch( () => {
                });
        },
        getQuestions() {
            if( this.selected_category && this.selected_category.id ) {
                // build api url
                var api_url = process.env.VUE_APP_API + '/admin/categories/' + this.selected_category.id + '/questions?page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage;

                if (this.options.sortBy.length === 1) {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0];
                }

                if(this.options.sortDesc.length === 1) {
                    if( this.options.sortDesc[0] ) {
                        api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=DESC';
                    } else {
                        api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=ASC';
                    }
                }

                this.loadingQuestions = true;
                this.$http
                    .get(api_url)
                    .then(response => {
                        this.questions = response.data.data;
                        this.totalQuestions = response.data.meta.total;
                        this.options.page = response.data.meta.current_page;
                        this.options.itemsPerPage = response.data.meta.per_page;
                        this.loadingQuestions = false;
                    }).catch( () => {
                        this.loadingQuestions = false;
                    });
            }
            
        },
        selectCategory(category) {
            this.selected_category = category;
            // reset options
            this.options.page = 1;
            this.getQuestions();
        },
        saveCategory() {
            this.loadingCreateCategory = true;
            this.error_save = false;
            this.$http
                .post(process.env.VUE_APP_API + '/admin/categories', {
                    title: this.category_title,
                    description: this.category_description,
                    descriptionfront: this.category_descriptionfront
                })
                .then( () => {
                    this.loadingCreateCategory = false;
                    this.modal_create_category = false;
                    // reload categories
                    this.getCategories();
                }).catch( error => {
                    this.error_save = error.response.data;
                    this.loadingCreateCategory = false;
                });
        },
        newQuestion() {
            // reset question fields
            this.resetQuestionFields();
            // open panel
            this.panel_create_question = true
        },
        resetQuestionFields() {
            this.question = {
                id: false,
                title: '',
                description: '',
                descriptionfront: '',
                type: '',
                access: 'public',
                status: 'active',
                category_id: '',
                companies: [],
                answers: []
            };
            this.can_edit_type = true;
        },
        displayAnswersFields(value) {
            if( value === 'note' ) {
                this.question.answers = [
                    {title: '0', points: 0, position: 0},
                    {title: '1', points: 10, position: 1},
                    {title: '2', points: 20, position: 2},
                    {title: '3', points: 30, position: 3},
                    {title: '4', points: 40, position: 4},
                    {title: '5', points: 50, position: 5}
                ];
            } else if( value === 'oui_non' ) {
                this.question.answers = [
                    {title: 'Oui', points: 10, position: 0},
                    {title: 'Non', points: 0, position: 1}
                ];
            } else if( value === 'echelle' ) {
                this.question.answers = [
                    {title: "Pas du tout", points: 0, position: 0},
                    {title: "Pas vraiment", points: 10, position: 1},
                    {title: "ni d'accord ni pas d'accord", points: 20, position: 2},
                    {title: "Plutôt d'accord", points: 30, position: 3},
                    {title: "Tout à fait d'accord", points: 40, position: 4},
                ];
            } else if( value === 'custom' ) {
                this.question.answers = [
                    {title: "", points: 0, position: 0},
                    {title: "", points: 0, position: 1},
                    {title: "", points: 0, position: 2},
                ];
            } else if( value === 'multiple' ) {
                this.question.answers = [
                    {title: "", points: 0, position: 0},
                    {title: "", points: 0, position: 1},
                    {title: "", points: 0, position: 2},
                ];
            }
        },
        saveQuestion() {
            this.loadingCreateQuestion = true;
            this.error_save_question = false;

            // if question id is set
            if( this.question.id ) {
                // update question
                this.$http
                    .put(process.env.VUE_APP_API + '/admin/questions/' + this.question.id, {
                        title: this.question.title,
                        description: this.question.description,
                        descriptionfront: this.question.descriptionfront,
                        type: this.question.type,
                        access: this.question.access,
                        status: this.question.status,
                        category_id: this.question.category_id,
                        companies: this.question.companies,
                        answers: this.question.answers,
                    })
                    .then( () => {
                        this.loadingCreateQuestion = false;
                        this.panel_create_question = false;
                        // reset question
                        this.resetQuestionFields();
                        // reload categories and questions
                        this.getCategories();
                    }).catch( error => {
                        this.error_save_question = error.response.data;
                        this.loadingCreateQuestion = false;
                    });
            } else {
                // save new question
                this.$http
                    .post(process.env.VUE_APP_API + '/admin/questions', {
                        title: this.question.title,
                        description: this.question.description,
                        descriptionfront: this.question.descriptionfront,
                        type: this.question.type,
                        access: this.question.access,
                        status: this.question.status,
                        category_id: this.question.category_id,
                        companies: this.question.companies,
                        answers: this.question.answers,
                    })
                    .then( () => {
                        this.loadingCreateQuestion = false;
                        this.panel_create_question = false;
                        // reset question
                        this.resetQuestionFields();
                        // reload categories and questions
                        this.getCategories();
                    }).catch( error => {
                        this.error_save_question = error.response.data;
                        this.loadingCreateQuestion = false;
                    });
            }
        },
        editQuestion(q) {
            this.question = q;
            // control admin can change question type
            if( this.question.formelements_count && this.question.formelements_count > 0 ) {
                this.can_edit_type = false;
            } else {
                this.can_edit_type = true;
            }
            this.panel_create_question = true;
        },
        confirmDeleteQuestion(q) {
            this.question_to_delete = q;
            this.modal_delete_question = true;
        },
        deleteQuestion() {
            this.loadingDeleteQuestion = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/admin/questions/' + this.question_to_delete.id)
                .then(() => {
                    this.loadingDeleteQuestion = false;
                    this.modal_delete_question = false;
                    this.question_to_delete = false;
                    this.snackbarText = "Question supprimée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload categories
                    this.getCategories();
                }).catch( () => {
                    this.loadingDeleteQuestion = false;
                });
        },
        deleteAnswer(question, index_answer) {
            // remove answer
            question.answers.splice(index_answer, 1);
            // recalculate positions
            this.recalculatePositions(question);
        },
        addAnswer(question) {
            // add an answer
            question.answers.push({
                title: "",
                points: 0,
                position: 0
            });
            // recalculate positions
            this.recalculatePositions(question);
        },
        recalculatePositions(question) {
            let position = 0;
            question.answers.forEach( (ans) =>{
                ans.position = position;
                position = position*1 + 1;
            });
        }
    },
     watch: {
        options: {
            handler () {
                this.getQuestions();
            },
            deep: true,
        },
    },
    components: {
    }
}
</script>
