<template>
    <v-app>
        <component :is="layout">
            <router-view/>
        </component>
    </v-app>
</template>


<script>
const default_layout = "default";

export default {
    data() {
        return {
            loading: true,
        }
    },
    computed : {
        layout() {
            return (this.$route.meta.layout || default_layout) + "-layout";
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    if( this.$route.name != 'login' ) {
                        this.$router.push({
                            name: 'login',
                        });
                    }
                })
        }
    },
    created: function () {
        let self = this;
        this.$http.interceptors.response.use(undefined, function (err) {
            return new Promise(function (/*resolve, reject*/) {
                self.loading = false;
                if( err.response.status === 401 ) {
                    self.$router.push('/login');
                } else if( err.response.status === 403 ) {
                    //console.log(err.response.data.message);
                     self.$router.push({
                        path: '/404'
                    }).catch(() => {});
                }
                
                throw err;
            });
        });
    }
}
</script>

<style lang="scss">
    @import 'assets/styles/main.scss';
</style>