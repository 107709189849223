<template>
    <v-container
        class="pb-5"
        fluid
    >
        <v-row class="mb-0" v-if="form">
            <v-col class="mb-0 pb-0">
                <small>
                    <router-link class="primary--text text-decoration-none" :to="{ name: 'forms.edit', params:{id: form.id} }">
                        <v-icon small color="blue">mdi-arrow-left</v-icon> Retour à l'édition
                    </router-link>
                </small>
                <h2 class="mb-0">
                    Statistiques - <strong>{{ form.title }}</strong><br>
                </h2>
            </v-col>
        </v-row>
        <v-row v-if="loadingStats">
            <v-col class="text-center pt-5">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col lg="3" md="3" sm="4" cols="6">
                <v-card style="height: 100%">
                    <v-card-text class="text-center">
                        <h3 class="mb-3">Invitations envoyées</h3>
                        <div class="primary--text font-weight-bold text-h2" style="">
                            {{ stats.nb_testers }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="3" md="3" sm="4" cols="6">
                <v-card style="height: 100%">
                    <v-card-text class="text-center">
                        <h3 class="mb-3">Réponses</h3>
                        <v-progress-circular
                            :rotate="-90"
                            :size="100"
                            :width="15"
                            :value="stats.nb_testers_complete_percent"
                            color="primary"
                        >
                            {{ stats.nb_testers_complete }} / {{ stats.nb_testers }}
                        </v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="3" md="3" sm="4" cols="6">
                <v-card style="height: 100%" class="d-flex align-center">
                    <v-card-text class="text-center">
                        <v-btn color="primary" outlined @click.prevent="downloadStats" class="mb-2" :loading="loadingCSV">
                            <v-icon small color="blue">mdi-download</v-icon> Télécharger en CSV
                        </v-btn>
                        <v-btn color="primary" outlined :to="{ name: 'forms.answers', params:{id: form.id} }" v-if="form && form.enablelisting && form.enablelisting === 1">
                            Détails des réponses
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- <div>
                Nombre de répondants : {{ stats.nb_testers }}
            </div>
            <div class="mb-5">
                Nombre de répondants ayant terminé : {{ stats.nb_testers_complete_percent }}% ({{ stats.nb_testers_complete }})
            </div> -->
        </v-row>
        <v-row>
            <v-col>
                <div v-for="formelement in stats.formelements" :key="'formelement'+formelement.id">
                    <div v-if="formelement.question" class="mb-5">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="4" md="4" sm="5" cols="12">
                                        <h3>{{ formelement.question.title }}</h3>
                                        {{ formelement.question.nb_points }} / {{ formelement.question.nb_points_max }} point<template v-if="formelement.question.nb_points_max > 1">s</template>
                                        <!--{{ formelement.question.nb_answers }} réponse(s) - {{ formelement.question.nb_answers_percent }}% - {{ formelement.question.nb_points }} / {{ formelement.question.nb_points_max }} point(s)-->
                                    </v-col>
                                    <v-col>
                                        <template v-if="formelement.question.answers && formelement.question.answers.length > 0">
                                            <div v-for="answer in formelement.question.answers" :key="'answer_'+answer.id" class="mb-2">
                                                {{ answer.title }} <small>({{ answer.points }} point<template v-if="answer.points > 1">s</template>)</small>
                                                <v-progress-linear
                                                    :value="answer.nb_selected_percent"
                                                    color="green"
                                                    rounded
                                                    height="15"
                                                >
                                                    <template v-slot:default="{ value }">
                                                        <strong>{{ value }}%</strong>
                                                    </template>
                                                </v-progress-linear>
                                                <!-- {{ answer.nb_selected }} - {{ answer.nb_selected_percent }}% -->
                                            </div>
                                        </template>
                                        <template v-else>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr
                                                            v-for="answer in formelement.testeranswers"
                                                            :key="'comm_'+answer.id"
                                                        >
                                                            <td>
                                                                {{ answer.comment }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import downloadCSV from '@/functions/downloadCSV.js';

export default {
    data() {
        return {
            loadingStats: false,
            form: false,
            stats: false,
            loadingCSV: false,
        }
    },
    created() {
        this.getStats();
    },
     methods: {
        getStats() {            
            this.loadingStats = true;
            this.$http
                .get(process.env.VUE_APP_API + '/forms/' + this.$route.params.id + '/stats' )
                .then(response => {
                    this.stats = response.data.stats;
                    this.form = response.data.form;
                    this.loadingStats = false;
                }).catch( () => {
                    this.loadingStats = false;
                });
        },
         downloadStats() {
            this.loadingCSV = true;
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.$route.params.id + '/stats/download', {}, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadCSV(response.data, 'export');
                    this.loadingCSV = false;
                }).catch( () => {
                //console.log(error);
                this.loadingCSV = false;
            });
         },
    }
}
</script>
