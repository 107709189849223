<template>
    <v-container
        class="pb-5"
        fluid
    >
        <v-snackbar
                v-model="snackbar"
                right
                :color="snackbarColor"
            >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>


        <v-dialog v-model="modal_delete" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Supprimer ou anonymiser</h3>
                </v-card-title>
                <v-card-text>
                    <p>
                        Vous pouvez soit anonymiser le tester <em>{{ tester_to_delete.email }}</em>, soit le supprimer définitivement.
                    </p>
                    <p>
                        En le supprimant, toutes ses données seront définitivement supprimées.
                    </p>

                    <v-progress-circular
                        v-if="loadingDeleteBtn"
                        indeterminate
                        color="red"
                    ></v-progress-circular>
                    <v-btn v-else color="red" dark class="mr-2" @click.prevent="deleteTester">
                        Supprimer
                    </v-btn>

                    <v-progress-circular
                        v-if="loadingAnonBtn"
                        indeterminate
                        color="orange"
                    ></v-progress-circular>
                    <v-btn v-else color="orange" dark class="mr-1" @click.prevent="anonTester">
                        Anonymiser
                    </v-btn>
                    
                    <v-btn color="darken-1" class="ml-1" text @click="modal_delete=false">Annuler</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-row class="mb-0">
            <v-col class="mb-0 pb-0" v-if="form">
                <small>
                    <router-link class="primary--text text-decoration-none" :to="{ name: 'forms.edit', params:{id: form.id} }">
                        <v-icon small color="blue">mdi-arrow-left</v-icon> Retour à l'édition
                    </router-link>
                </small>
                <h2 class="mb-0">Envoyer le formulaire</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <v-card v-if="form" class="mb-4">
                    <v-card-text>
                        <h3 >
                            Votre questionnaire : <router-link class="primary--text font-weight-bold text-decoration-none" :to="{ name: 'forms.edit', params:{id: form.id} }">{{ form.title }}</router-link>
                        </h3>
                        <p v-if="form.description && form.description.length > 0">
                            {{ form.description }}
                        </p>
                        <div class="primary--text font-weight-bold text-h6">
                            - {{ form.nb_questions }} question<template v-if="form.nb_questions > 0">s</template><br>
                            - {{ form.nb_sections }} section<template v-if="form.nb_sections > 0">s</template>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <h3 class="mb-2">
                            Saisissez les adresses mails des personnes à qui envoyer le questionnaire :
                        </h3>
                        <div>
                            <v-form @submit="controlEmails">
                                <v-textarea v-model="raw_emails" label="exemple@ifth.com" outlined></v-textarea>
                                <div class="text-right">
                                    <v-btn color="primary" @click.prevent="controlEmails">Valider</v-btn>
                                </div>
                            </v-form>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card v-if="form">
                    <v-card-text>
                        <div v-if="form.token && form.token.length > 0">
                            <h3>
                                La saisie de réponses à la volée est active
                            </h3>
                            <p>
                                Vous pouvez accéder à la page ici : <a target="_blank" :href="app_url + '/inscription-formulaire/' + form.token">{{ app_url }}/inscription-formulaire/{{ form.token }}</a>
                            </p>
                        </div>
                        <div v-else>
                            <h3>
                                Activer la saisie de réponses à la volée
                            </h3>
                        </div>
                        
                        <div class="text-center pt-3">
                            <v-progress-circular
                                v-if="loadingToken"
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                            <v-btn v-else @click.prevent="toggleToken">
                                <span v-if="form.token && form.token.length > 0">
                                    Désactiver
                                </span>
                                <span v-else>
                                    Activer
                                </span>
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="pt-0">
                <v-card v-if="form && form.testers">
                    <v-card-text>
                        <h3>
                            Liste des testeurs :
                        </h3>
                        <v-simple-table class="mb-2">
                            <thead>
                                <tr>
                                    <th>E-mail</th>
                                    <th>Lien envoyé ?</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="tester in form.testers" :key="'tester_'+tester.id">
                                    <td>
                                        {{ tester.email }}
                                    </td>
                                    <td>
                                        <span v-if="tester.sent">
                                            <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon> Oui
                                        </span>
                                        <span v-else>
                                            <v-icon color="orange">mdi-close-circle-outline</v-icon> Non
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="tester.sent">
                                            <template v-if="loadingResend === tester.id">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                    size="20"
                                                    width="3"
                                                ></v-progress-circular>
                                            </template>
                                            <v-btn small outlined color="primary" class="mr-2" @click.prevent="reSendForm(tester)" v-else>
                                                Renvoyer le lien
                                            </v-btn>
                                        </span>
                                        <template v-if="loadingDelete === tester.id">
                                            <v-progress-circular
                                                indeterminate
                                                color="red"
                                                size="20"
                                                width="3"
                                            ></v-progress-circular>
                                        </template>
                                        <a href="#" @click.prevent="confirmeDeleteTester(tester)" v-else>
                                            <v-icon color="red">mdi-minus-circle-outline</v-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <template v-if="loadingSend">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </template>
                        <v-btn color="primary" @click.prevent="sendForm" class="mb-1" v-else>Envoyer à tous</v-btn><br>
                        <small><em>Seules les personnes n'ayant pas encore eu d'invitation recevront le mail.</em></small>
                    </v-card-text>
                    
                    
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            loadingForm: false,
            headers: [
                { text: 'Email', value: 'email', sortable: true, },
                { text: 'Envoyé', value: 'sent', sortable: true, },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],
            form: false,
            raw_emails: '',
            emails: [],
            loadingDelete: false,
            loadingSend: false,
            loadingResend: false,

            tester_to_delete: false,
            modal_delete: false,
            loadingDeleteBtn: false,

            loadingAnonBtn: false,
            loadingToken: false,

            app_url: process.env.VUE_APP_FORMURL
        }
    },
    created() {
        this.getForm();
    },
     methods: {
        getForm() {            
            this.loadingForm = true;
            this.$http
                .get(process.env.VUE_APP_API + '/forms/' + this.$route.params.id + '/testers' )
                .then(response => {
                    this.form = response.data.data;
                    this.loadingForm = false;
                }).catch( () => {
                    this.loadingForm = false;
                });
        },
        controlEmails() {
            this.loadingEmails = true;
            this.raw_emails = this.raw_emails.replace(/(\r\n|\n|\r)/gm, " ");
            let temp_emails = this.raw_emails.split(' ');
            temp_emails.forEach( (e) => {
                if( this.validEmail(e) ) {
                    this.emails.push(e);
                }
            });
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.$route.params.id + '/testers', {
                    emails: this.emails   
                })
                .then( () => {
                    this.getForm();
                    this.loadingEmails = false;
                    this.emails = [];
                    this.raw_emails = '';
                }).catch( () => {
                    this.loadingEmails = false;
                });
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        confirmeDeleteTester(tester) {
            this.tester_to_delete = tester;
            this.modal_delete = true;
        },
        deleteTester() {
            this.loadingDelete = this.tester_to_delete.id;
            this.loadingDeleteBtn = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/forms/' + this.form.id + '/testers/' + this.tester_to_delete.id)
                .then(() => {
                    this.loadingDelete = false;
                    this.modal_delete = false;
                    this.tester_to_delete = false;
                    this.snackbarText = "Testeur supprimé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.loadingDeleteBtn = false;

                    // reload form and testers
                    this.getForm();
                }).catch( () => {
                    this.loadingDelete = false;
                    this.loadingDeleteBtn = false;
                });
        },
        anonTester() {
            this.loadingAnonBtn = true;
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/testers/' + this.tester_to_delete.id + '/anon')
                .then(() => {
                    this.loadingAnonBtn = false;
                    this.modal_delete = false;
                    this.tester_to_delete = false;
                    this.snackbarText = "Testeur anonymisé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload form and testers
                    this.getForm();
                }).catch( () => {
                    this.loadingDelete = false;
                    this.loadingAnonBtn = false;
                });
        },
        sendForm() {
            this.loadingSend = true;
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/send')
                .then(() => {
                    this.loadingSend = false;
                    this.snackbarText = "Emails envoyés aux testeurs";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload form and testers
                    this.getForm();
                }).catch( () => {
                    this.loadingSend = false;
                });
        },
        reSendForm(tester) {
            this.loadingResend = tester.id;
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/send/' + tester.id)
                .then(() => {
                    this.loadingResend = false;
                    this.snackbarText = "Emails envoyés aux testeurs";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload form and testers
                    this.getForm();
                }).catch( () => {
                    this.loadingResend = false;
                });
        },
        toggleToken() {
            this.loadingToken = true;
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/token')
                .then(() => {
                    this.loadingToken = false;
                    this.snackbarText = "Accès à la volée modifié";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload form and data
                    this.getForm();
                }).catch( () => {
                    this.loadingToken = false;
                });
        }
    }
}
</script>
