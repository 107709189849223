<template>
    <v-container
        class="pb-5"
        fluid
    >   
        <v-row>
            <v-col>
                <v-form @submit.prevent="searchForm">
                    <v-row align="center" class="mb-2">
                        <v-col cols="8" sm="4" md="4">
                            <v-text-field
                                v-model="search"
                                label="Rechercher"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="2" md="2">
                            <v-btn type="submit" depressed color="white">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="text-right">
                            <v-btn color="primary" depressed @click.prevent="panel_create_form = true">
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Nouveau questionnaire
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        
        <Loading v-if="loading" />
        <div v-else>
            <v-card :elevation="0" class="rounded-lg">
                <v-card-text>
                    <v-row v-if="forms && forms.length > 0">
                        <v-col v-for="form in forms" :key="'form_'+form.id" sm="6" md="4" lg="3">
                            <v-card :elevation="0" class="ifthcard--light rounded-lg">
                                <v-card-text class="pb-2">
                                    <div class="text-right mb-5" style="height:24px">
                                        <v-chip small v-if="form.status === 'sent'" color="primary">
                                            Envoyé
                                        </v-chip>
                                        <v-chip small v-if="form.status === 'complete'" color="success" class="ml-2">
                                            Terminé
                                        </v-chip>
                                        <v-chip small v-if="form.ismodel" class="ml-2">
                                            Modèle
                                        </v-chip>
                                    </div>
                                    <h3 class="pt-5 pb-5 text-center font-weight-bold text-uppercase">
                                        {{ form.title }}
                                    </h3>
                                    <div class="mt-5">
                                        <small>Créé par {{ form.user.firstname }}</small>
                                    </div>
                                    <!-- <div v-if="form.description && form.description.length > 0">
                                        {{ form.description }}
                                    </div> -->
                                    <div>
                                        
                                    </div>
                                </v-card-text>
                                <v-card-text class="ifthcard-footer d-flex justify-center">
                                    <v-btn :to="{ name: 'forms.stats', params:{id: form.id} }" class="mr-1" color="warning" depressed rounded outlined>
                                        Statistiques
                                    </v-btn>
                                    <v-btn :to="{ name: 'forms.edit', params:{id: form.id} }" class="ml-1" color="primary" depressed rounded outlined>
                                        Éditer
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-col> 
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            Aucun formulaire
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <v-row v-if="this.options && this.options.last_page > 1">
            <v-pagination
                v-model="options.page"
                :length="options.last_page"
                circle
            ></v-pagination>
        </v-row>


        <v-navigation-drawer
            v-model="panel_create_form"
            absolute
            temporary
            right
            style="width:90%;max-width:700px"
            >
            <v-form @submit.prevent="saveForm" class="pa-5">
                <v-alert color="red" type="error" v-if="error_save_form && error_save_form.errors">
                    <div v-for="(err, index) in error_save_form.errors" :key="'err_'+index">
                        <template v-for="e in err">
                            {{ e }}
                        </template>
                    </div>
                </v-alert>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.title"
                            label="Nom du questionnaire*"
                            required
                        ></v-text-field>
                        <v-textarea
                            v-model="form.description"
                            label="Description"
                        ></v-textarea>
                        <v-select
                            v-model="form.access"
                            :items="[{
                                name: 'Privé',
                                value: 'default'
                            },{
                                name: 'Autoriser IFTH à y accéder',
                                value: 'ifth'
                            }]"
                            item-text="name"
                            item-value="value"
                            label="Visibilité*"
                            required
                        ></v-select>
                        <v-select
                            v-if="formmodels && formmodels.length > 0"
                            :items="formmodels"
                            item-text="form.title"
                            item-value="form_id"
                            v-model="formmodel_id"
                            label="Utiliser un modèle"
                            :clearable="true"
                        ></v-select>
                        <template v-if="$store.state.user.role === 'admin'">
                            <v-switch
                                v-model="form.enablelisting"
                                label="Activer le listing complet des résultats (par répondant)"
                            ></v-switch>
                        </template>
                        <div class="text-center">
                            <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveForm" :loading="loadingCreateForm" :disabled="loadingCreateForm">
                                Enregistrer
                            </v-btn>
                            <v-btn color="darken-1" class="ml-1" text @click="panel_create_form=false">Fermer</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-navigation-drawer>

    </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loading: false,
            forms: false,
            totalForms: 0,
            search: '',
            options: {
                page: 0,
                itemsPerPage: 12,
                sortBy: ['id'],
                sortDesc: [true],
                last_page: 1,
            },

            panel_create_form: false,
            error_save_form: false,
            form: {
                title: '',
                description: '',
                access: 'default',
                enablelisting: false
            },
            loadingCreateForm: false,

            loadingFormmodels: false,
            formmodels: [],
            formmodel_id: false,
        }
    },
    created() {
        this.options.page = 1; // change options.pageto start getForms
        this.getFormmodels();
        if( this.$route.query.create ) {
            this.panel_create_form = true;
        }
    },
     methods: {
        getForms() {
            this.loading = true;

            // build api url
            var api_url = process.env.VUE_APP_API + '/forms?page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage;

            if (this.options.sortBy.length === 1) {
                api_url = api_url + '&sortBy=' + this.options.sortBy[0];
            }

            if(this.options.sortDesc.length === 1) {
                if( this.options.sortDesc[0] ) {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=DESC';
                } else {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=ASC';
                }
            }

            if( this.search && this.search.length > 0 ) {
                api_url = api_url + '&search=' + this.search;
            }

            this.$http
                .get(api_url)
                .then(response => {
                    this.forms = response.data.data;
                    this.totalForms = response.data.meta.total;
                    this.options.page = response.data.meta.current_page;
                    this.options.itemsPerPage = response.data.meta.per_page;
                    this.options.last_page = response.data.meta.last_page;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getFormmodels() {
            this.loadingFormmodels = true;
            this.$http
                .get(process.env.VUE_APP_API + '/formmodels' )
                .then(response => {
                    this.formmodels = response.data.data;
                    this.loadingFormmodels = false;
                }).catch( () => {
                    this.loadingFormmodels = false;
                });
        },
        searchForm() {
            // go to paginate 1
            this.options.page = 1;
            // search results
            this.getForms();
        },
        saveForm() {
            this.loadingCreateForm = true;
            this.error_save_form = false;

            // create new form
            this.$http
                .post(process.env.VUE_APP_API + '/forms', {
                    title: this.form.title,
                    description: this.form.description,
                    access: this.form.access,
                    enablelisting: this.form.enablelisting,
                    formmodel_id: this.formmodel_id
                })
                .then( (response) => {
                    this.loadingCreateForm = false;
                    //this.panel_create_form = false;
                    // redirect to edit form page
                    this.$router.push({
                        name: 'forms.edit',
                        params: {
                            id: response.data.form.id,
                        }/*,
                        query: {
                            created: 1
                        }*/
                    });
                }).catch( error => {
                    this.error_save_form = error.response.data;
                    this.loadingCreateForm = false;
                });
        },
        resetFormFields() {
            this.form = {
                title: '',
                description: '',
                access: 'default'
            };
            this.can_edit_type = true;
        }
    },
    watch: {
        options: {
            handler () {
                this.getForms();
            },
            deep: true,
        },
    },
    components: {
        Loading
    }
}
</script>
