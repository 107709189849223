<template>
    <div class="mainmenu">
        <v-navigation-drawer
            v-model="displayMenu"
            :clipped="true"
            app
            color="#104c81"
            dark
        >
            <v-list dense flat>
                <v-list-item link :to="{ name: 'dashboard' }" :class="{ menuactive : $route.name === 'dashboard' }">
                    <v-list-item-action>
                    <v-icon>mdi-chart-tree</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'forms' }" :class="{ menuactive : $route.name === 'forms' || $route.name === 'forms.edit' || $route.name === 'forms.send' || $route.name === 'forms.stats' }">
                    <v-list-item-action>
                    <v-icon>mdi-chat-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Formulaires</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <template v-if="$store.state.user.role === 'admin'">
                    <v-list-item>
                        <small>Administrateurs</small>
                    </v-list-item>
                    <v-list-item link :to="{ name: 'companies' }" :class="{ menuactive : $route.name === 'companies' }">
                        <v-list-item-action>
                        <v-icon>mdi-domain</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>Entreprises</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link :to="{ name: 'users' }" :class="{ menuactive : $route.name === 'users' }">
                        <v-list-item-action>
                        <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>Utilisateurs</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link :to="{ name: 'admin_questions' }" :class="{ menuactive : $route.name === 'admin_questions' }">
                        <v-list-item-action>
                        <v-icon>mdi-file-document-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>Questions</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            app
            color="#104c81"
            :clipped-left="true"
            dark
            :elevation="0"
        >
            <v-app-bar-nav-icon @click.stop="displayMenu = !displayMenu"></v-app-bar-nav-icon>
            <v-toolbar-title class="pl-1 pt-4">
                <img class="img-fluid" width="200" src="@/assets/images/logo-ifth.png" alt="IFTH">
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            depressed
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            large
                            style="border:0"
                        >
                            <template v-if="profilpic && profilpic.length > 0">
                                <img :src="profilpic" class="accountPic accountPic--small mr-3">
                            </template> {{ name }} <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :to="{ name: 'account' }">
                            <v-list-item-title>
                                Mon compte
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click.prevent="logout">
                            <v-list-item-title>
                                Déconnexion
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
    </div>
</template>

<script>

    export default {
        props: [
            'drawer',
        ],
        data() {
            return {
                displayMenu: this.drawer,
                name: 'Mon compte',
                profilpic: false,
            }
        },
        created() {
            this.getFirstname();
        },
        methods: {
            getFirstname() {
                if( this.$store.state.user && this.$store.state.user.firstname ) {
                    this.name = this.$store.state.user.firstname;
                }
                if( this.$store.state.user && this.$store.state.user.picurl ) {
                    this.profilpic = this.$store.state.user.picurl;
                }
            },
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push({ name: 'login' })
                    })
            },
        }
    }
</script>