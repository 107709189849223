import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

// import layouts
import DefaultLayout from "./layouts/Default.vue";
import LoginLayout from "./layouts/Login.vue";
Vue.component("default-layout", DefaultLayout);
Vue.component("login-layout", LoginLayout);

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.withCredentials = true;
/*const token = Vue.$cookies.get('cooltraining_user_token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}*/

var vueapp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});


Axios
  .get(process.env.VUE_APP_API + '/auth/me')
  .then( response => {
    // user is connected, refresh token directly
    // update user data
    store.dispatch('update_user', response.data.user)
      .then(() => {})
      .catch(() => {});
    
    //we can mount the app
    vueapp.$mount('#app'); 
  }).catch(() => {
    // error, delete token cookie, and then mount the app
    vueapp.$mount('#app');
  });
