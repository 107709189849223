<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1>
            Entreprise
        </h1>

        <v-snackbar
            v-model="snackbar"
            right
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <h3>
                            Infos :
                        </h3>
                        <v-form @submit.prevent="saveCompany">
                            <v-text-field
                                v-model="company.name"
                                label="Nom*"
                                required
                            ></v-text-field>
                            <v-btn type="submit" color="primary" class="mr-1" @click.prevent="saveCompany" :loading="loadingSave" :disabled="loadingSave">
                                Enregistrer
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-text>
                        <h3>
                            Logo :
                        </h3>
                        <div v-if="errorsPic">
                            <v-alert color="red" type="error" v-if="errorsPic.errors">
                                <div v-for="(err, index) in errorsPic.errors" :key="index">
                                    <template v-for="e in err">
                                        {{ e }}
                                    </template>
                                </div>
                            </v-alert>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-md-4 col-12">
                                <img class="companyLogo" :src="company.logourl" v-if="company && company.logourl" />

                                <template v-if="company && company.logourl">
                                    <div class="text-center">
                                        <v-btn small color="red" @click.prevent="deletePic">
                                            Suppr. la photo
                                        </v-btn>
                                    </div>
                                </template>
                            </div>
                            <div class="col-md-8 col-12 pt-md-0 pt-sm-3 pt-3">
                                <div class="form-group">
                                    <label for="pic">Choisissez une image</label>
                                    <input type="file" id="pic" ref="pic" class="form-control" v-on:change="handleFileUpload()"  accept="image/*">
                                </div>
                                <div class="form-group">
                                    <v-btn color="primary" @click.prevent="changeLogoPic">
                                        Enregistrer
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            loadingSave: false,
            loadingPic: false,
            company: false,
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            errorsPic: false,
            pic: '',
            error_save: false,
        }
    },
    created() {
        if( this.$route.query.created ) {
            this.snackbarText = "Entreprise créée";
            this.snackbarColor = 'success';
            this.snackbar = true;
        }
        this.getCompany()
    },
     methods: {
        getCompany() {            
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/admin/companies/' + this.$route.params.id )
                .then(response => {
                    this.company = response.data.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        handleFileUpload() {
            this.pic = this.$refs.pic.files[0];
        },
        changeLogoPic() {
            this.loadingPic = true;
            this.errorsPic = null;
            let formData = new FormData();
            formData.append('pic', this.pic);
            this.$http
                .post(process.env.VUE_APP_API + '/admin/companies/' + this.$route.params.id + '/logo', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.loadingPic = false;
                    this.snackbarText = "Logo mis à jour";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    this.pic = '';

                    // reload company data
                    this.getCompany();

                }).catch( error => {
                    this.errorsPic = error.response.data;
                    this.loadingPic = false;
                });
        },
        deletePic() {
            this.loadingPic = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/admin/companies/' + this.$route.params.id + '/logo')
                .then(() => {
                    this.loadingPic = false;
                    this.snackbarText = "Logo supprimé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    // reload company data
                    this.getCompany();

                }).catch( () => {
                    this.loadingPic = false;
                });
        },
        saveCompany() {
            this.loadingSave = true;
            this.error_save = false;
            this.$http
                .put(process.env.VUE_APP_API + '/admin/companies/' + this.$route.params.id, {
                    name: this.company.name
                })
                .then( () => {
                    this.loadingSave = false;
                    // display success
                    this.snackbarText = "Entreprise mise à jour";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( error => {
                    this.error_save = error.response.data;
                    this.loadingSave = false;
                });
        }
    },
    watch: {
    },
    components: {
    }
}
</script>
