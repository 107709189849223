<template>
    <div class="loginlayout fill-height">
        <v-main>
            <v-container
                class="fill-height"
                fluid
            >
                <v-row
                    align="center"
                    justify="center"
                >
                    <v-col
                        cols="12"
                        sm="12"
                        md="10"
                        lg="8"
                    >
                        <slot />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    export default {
    }
</script>