<template>
    <v-container
        class="pb-5"
        fluid
    >
        <h1 class="mb-2">
            Dashboard
        </h1>
        
        <Loading v-if="loading" />
        <div v-else>
            <template v-if="stats && stats.forms_stats && stats.forms_stats.length > 0">
                <v-row>
                    <v-col md="4" sm="6" cols="12">
                        <v-card class="mb-4">
                            <v-card-text class="text-center">
                                <div class="primary--text text-h2 font-weight-black">{{ stats.nb_forms }}</div>
                                <div class="font-weight-bold black--text text-h6">formulaires créés</div>
                            </v-card-text>
                        </v-card>
                        <v-card>
                            <v-card-text class="text-center">
                                <div class="green--text text-h2 font-weight-black">{{ stats.nb_testers }}</div>
                                <div class="font-weight-bold black--text text-h6">testeurs</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="4" sm="6" cols="12">
                        <v-card>
                            <v-card-text class="text-center">
                                <apexchart type="radialBar" :options="radialOptions" :series="radialSeries"></apexchart>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="4" sm="6" cols="12">
                        <v-card>
                            <v-card-text class="text-center">
                                <apexchart type="donut" :options="chartOptions" :series="chartSeries"></apexchart>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text>
                                <h3 class="text-center">% de réponses par formulaire</h3>
                                <div>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th>DERNIERS FORMULAIRES</th>
                                                <th style="width:50%"></th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="f in stats.forms_stats" :key="'form_'+f.form.id">
                                                <td>
                                                    {{ f.form.title }}
                                                    <v-chip small v-if="f.form.status === 'sent'" color="primary">
                                                        Envoyé
                                                    </v-chip>
                                                    <v-chip small v-if="f.form.status === 'complete'" color="success" class="ml-2">
                                                        Terminé
                                                    </v-chip>
                                                    <v-chip small v-if="f.form.ismodel" class="ml-2">
                                                        Modèle
                                                    </v-chip>
                                                </td>
                                                <td>
                                                    <v-progress-linear
                                                        :value="f.nb_testers_complete_percent"
                                                        color="green"
                                                        rounded
                                                        height="15"
                                                    >
                                                        <template v-slot:default="{ value }">
                                                            <strong>{{ value }}%</strong>
                                                        </template>
                                                    </v-progress-linear>
                                                </td>
                                                <td>
                                                    <router-link :to="{ name: 'forms.stats', params:{id: f.form.id} }" class="mb-2 orange--text">
                                                        <v-icon small color="orange">mdi-chart-bar</v-icon> Statistiques
                                                    </router-link><br>
                                                    <router-link :to="{ name: 'forms.edit', params:{id: f.form.id} }" class="">
                                                        <v-icon small color="blue">mdi-pencil</v-icon> Éditer
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-card>
                    <v-card-text class="text-center">
                        <div class="mb-3">
                            <em>
                                Créez votre premier questionnaire pour obtenir des statistiques.
                            </em>
                        </div>
                        <div>
                            <v-btn :to="{ name: 'forms', query: {create: 1} }" color="primary">
                                Créer un questionnaire
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </div>
    </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loading: false,
            stats: false,
            chartSeries: [],
            chartOptions: {
                chart: {
                    type: 'donut'
                },
                labels: ['Brouillons', 'Envoyés', 'Terminés'],
                colors: ['#aaa', '#249efa', '#24e5a5'],
                fill: {
                    colors: ['#aaa', '#249efa', '#24e5a5']
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        }
                    }
                }],
                legend: {
                    show: true,
                    position: 'bottom'
                }
            },
            radialOptions: {
                chart: {
                    type: 'radialBar'
                },
                labels: ['Formulaires envoyés', 'Réponses obtenues'],
                legend: {
                    show: true,
                    position: 'bottom'
                }
            },
            radialSeries: []
        }
    },
    created() {
        this.getStats();
    },
    methods: {
        getStats() {
            this.loading = true;

            let api_url = process.env.VUE_APP_API + '/stats';
            this.$http
                .get(api_url)
                .then(response => {
                    this.loading = false;
                    this.stats = response.data.stats;
                    this.chartSeries = [
                        response.data.stats.nb_forms_draft,
                        response.data.stats.nb_forms_sent,
                        response.data.stats.nb_forms_complete
                    ];
                    this.radialSeries = [
                        response.data.stats.nb_forms_sent_complete_percent,
                        response.data.stats.nb_answers_percent
                    ]
                }).catch( () => {
                    this.loading = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>
