<template>
    <div class="loading" :class="{ hover : hover }">
        <div class="pt-2 pb-5">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <div class="loading-message mt-2">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'hover',
            'small'
        ],
    }
</script>