<template>
    <v-container
        class="pb-5"
        fluid
    >
        <v-snackbar
                v-model="snackbar"
                right
                :color="snackbarColor"
            >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="modal_edit" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Infos du formulaire</h3>
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="updateForm">
                        <v-alert color="red" type="error" v-if="error_save_form && error_save_form.errors">
                            <div v-for="(err, index) in error_save_form.errors" :key="'err_'+index">
                                <template v-for="e in err">
                                    {{ e }}
                                </template>
                            </div>
                        </v-alert>
                        <v-text-field
                            v-model="form.title"
                            label="Nom du questionnaire*"
                            required
                        ></v-text-field>
                        <v-textarea
                            v-model="form.description"
                            label="Description"
                        ></v-textarea>
                        <v-select
                            v-model="form.access"
                            :items="[{
                                name: 'Privé',
                                value: 'default'
                            },{
                                name: 'Autoriser IFTH à y accéder',
                                value: 'ifth'
                            }]"
                            item-text="name"
                            item-value="value"
                            label="Visibilité*"
                            required
                        ></v-select>
                        <template v-if="$store.state.user.role === 'admin'">
                            <v-switch
                                v-model="form.enablelisting"
                                label="Activer le listing complet des résultats (par répondant)"
                            ></v-switch>
                        </template>
                        <div class="text-center">
                            <v-btn type="submit" color="primary" class="mr-1" @click.prevent="updateForm" :loading="loadingSave" :disabled="loadingSave">
                                Enregistrer
                            </v-btn>
                            <v-btn color="darken-1" class="ml-1" text @click="modal_edit=false">Fermer</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="modal_delete" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Confirmer la suppression</h3>
                </v-card-title>
                <v-card-text>
                    <p v-if="element_to_delete.type === 'question'">
                        Êtes-vous sûr de vouloir supprimer la question <em>"{{ element_to_delete.question.title }}"</em> du formulaire ?
                    </p>
                    <p v-else>
                        Êtes-vous sûr de vouloir supprimer cet élément du formulaire ?
                    </p>

                    <v-progress-circular
                        v-if="loadingDeleteBtn"
                        indeterminate
                        color="red"
                    ></v-progress-circular>
                    <v-btn v-else color="red" dark class="mr-1" @click.prevent="deleteFormelement">
                        Supprimer
                    </v-btn>
                    
                    <v-btn color="darken-1" class="ml-1" text @click="modal_delete=false">Annuler</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-row>
            <v-col cols="4">
                <h2>
                    Formulaire <span><a @click.prevent="modal_edit = true" v-if="form">infos <v-icon small color="blue">mdi-pencil</v-icon></a></span>
                </h2>
            </v-col>
            <v-col class="text-right" cols="8">
                <v-btn v-if="$store.state.user.role === 'admin'" @click.prevent="openPanelModel" class="mr-3 mb-2" color="primary" outlined>
                    <template v-if="form && form.ismodel">
                        Options du modèle
                    </template>
                    <template v-else-if="form">
                        Définir comme modèle
                    </template>
                </v-btn>
                <v-btn @click.prevent="panel_order_questions = true" color="grey darken-3" dark class="mr-3 mb-2">
                    Ordonner les questions <span v-if="form && form.formelements">({{ form.formelements.length }})</span>
                </v-btn>
                <v-btn v-if="form && form.id" color="primary" :to="{ name: 'forms.send', params:{id: form.id} }" class="mb-2">
                    Envoyer
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="form && form.status && form.status === 'creating'">
            <v-col lg="4" md="4" sm="5" cols="12">
                <h3 class="mb-2">CATÉGORIES</h3>
                <v-card :elevation="1" class="rounded-lg">
                    <v-simple-table>
                        <tbody>
                            <tr v-for="category in categories" :key="'main_'+category.id" style="cursor:pointer" @click="selectCategory(category)" :class="{selected: selected_category.id === category.id}">
                                <td>
                                    {{ category.title }} ({{ category.questions_count }})
                                </td>
                                <td class="text-right">
                                    <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col>
                <h3 class="mb-2">QUESTIONS</h3>
                <v-data-table
                    :headers="headers"
                    :items="questions"
                    :options.sync="options"
                    :server-items-length="totalQuestions"
                    :loading="loadingQuestions"
                    :no-data-text="'Aucune question trouvée'"
                    class="elevation-0 rounded-lg"
                    :footer-props="{
                        'items-per-page-text': 'Résultats par page',
                        'items-per-page-options': [10,20,50,100]
                    }"
                    fluid
                >
                    <template v-slot:item.title="{ item }">
                        {{ item.title }} <v-chip color="darkgrey" class="ml-2" dark small><em>{{ item.type }}</em></v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top v-if="item.description && item.description.length > 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    class="mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >mdi-eye-outline</v-icon>
                            </template>
                            <span>{{ item.description }}</span>
                        </v-tooltip>
                        
                        <template v-if="loadingAddQuestion === item.id">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                size="20"
                                width="3"
                            ></v-progress-circular>
                        </template>
                        <a @click.prevent="selectQuestion(item)" v-else>
                            <v-icon color="primary">mdi-plus-circle</v-icon>
                        </a>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <v-card>
                    <v-card-text v-if="form.status === 'sent'" class="text-center pa-5">
                        <h3>Formulaire envoyé</h3>
                        <p class="mb-2">
                            Vous avez déjà envoyé ce questionnaire, vous ne pouvez donc plus ajouter de questions.
                        </p>
                        <p class="mb-1">
                            <v-btn color="primary" outlined :to="{ name: 'forms.stats', params:{id: form.id} }">
                                Accéder aux statistiques
                            </v-btn>
                        </p>
                    </v-card-text>
                    <v-card-text v-else-if="form.status === 'complete'" class="text-center pa-5">
                        <h3>Formulaire terminé</h3>
                        <p class="mb-2">
                            Le questionnaire est terminé, vous ne pouvez donc plus ajouter de questions.
                        </p>
                        <p class="mb-1">
                            <v-btn color="primary" outlined :to="{ name: 'forms.stats', params:{id: form.id} }">
                                Accéder aux statistiques
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-navigation-drawer
            v-model="panel_order_questions"
            absolute
            :permanent="panel_order_questions"
            temporary
            right
            style="width:90%;max-width:900px"
            class="pa-5"
            >
            <div v-if="form && form.formelements && form.formelements.length > 0" class="pa-5 pb-0 position-relative">
                <div style="position:absolute; top:10px; left:10px; cursor:pointer;" @click="panel_order_questions = false">
                    <v-icon large color="black">mdi-close</v-icon>
                </div>
                <h2 class="mb-4 font-weight-normal">{{ form.title }}</h2>
                <draggable v-model="form.formelements" handle=".handle" draggable=".item" @end="saveOrder">
                    <transition-group>
                        <div v-for="element in form.formelements" :key="'element_'+element.id" class="item">
                            <div v-if="element.type === 'question'" class="ifthquestions-line rounded pa-3">
                                <v-row>
                                    <v-col cols="1" class="pl-4">
                                        <span class="handle">
                                            <v-icon>mdi-arrow-all</v-icon>
                                        </span>
                                    </v-col>
                                    <v-col cols="1">
                                        #{{ element.question.id }}
                                    </v-col>
                                    <v-col>
                                        {{ element.question.title }} <v-chip color="darkgrey" class="ml-2" dark small>{{ element.question.type }}</v-chip>
                                    </v-col>
                                    <v-col cols="3" class="text-right pr-5">
                                        <template v-if="loadingDeleteElement === element.id">
                                            <v-progress-circular
                                                indeterminate
                                                color="red"
                                                size="20"
                                                width="3"
                                            ></v-progress-circular>
                                        </template>
                                        <a @click.prevent="confirmDeleteElement(element)" v-else>
                                            <v-icon color="red">mdi-minus-circle-outline</v-icon>
                                        </a>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else-if="element.type === 'section'" class="ifthquestions-line rounded pa-3 blue lighten-5 mt-4">
                                <v-row>
                                    <v-col cols="1" class="pl-4">
                                        <span class="handle">
                                            <v-icon>mdi-arrow-all</v-icon>
                                        </span>
                                    </v-col>
                                    <v-col cols="2">
                                        SECTION
                                    </v-col>
                                    <v-col class="text-center">
                                        <div v-if="edit_section_id === element.id">
                                            <v-form @submit.prevent="updateFormelement(element)">
                                                <v-row >
                                                    <v-col cols="8">
                                                        <v-text-field dense type="text" v-model="element.text" outlined placeholder="Personnalisez la section"></v-text-field>
                                                    </v-col>
                                                    <v-col class="text-left pt-4 pl-0">
                                                        <v-btn small @click.prevent="updateFormelement(element)">Valider</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </div>
                                        <div v-else>
                                            <div v-if="element.text && element.text.length > 0">
                                                {{ element.text }}
                                            </div>
                                            <div v-else @click.prevent="edit_section_id = element.id">
                                                Personnalisez la section
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="3" class="text-right pr-5">
                                        <a @click.prevent="edit_section_id = element.id" class="mr-2">
                                            <v-icon>mdi-circle-edit-outline</v-icon>
                                        </a>
                                        <template v-if="loadingDeleteElement === element.id">
                                            <v-progress-circular
                                                indeterminate
                                                color="red"
                                                size="20"
                                                width="3"
                                            ></v-progress-circular>
                                        </template>
                                        <a @click.prevent="confirmDeleteElement(element)" v-else>
                                            <v-icon color="red">mdi-minus-circle-outline</v-icon>
                                        </a>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else-if="element.type === 'text'" class="ifthquestions-line rounded pa-3 grey lighten-3">
                                <v-row>
                                    <v-col cols="1" class="pl-4">
                                        <span class="handle">
                                            <v-icon>mdi-arrow-all</v-icon>
                                        </span>
                                    </v-col>
                                    <v-col cols="2">
                                        TXT LIBRE
                                    </v-col>
                                    <v-col class="text-center">
                                        <div v-if="edit_section_id === element.id">
                                            <v-form @submit.prevent="updateFormelement(element)">
                                                <v-row >
                                                    <v-col cols="8">
                                                        <v-textarea
                                                            outlined
                                                            placeholder="Personnalisez le texte"
                                                            v-model="element.text"
                                                            class="mb-0"
                                                        ></v-textarea>
                                                    </v-col>
                                                    <v-col class="text-left pt-4 pl-0">
                                                        <v-btn small @click.prevent="updateFormelement(element)">Valider</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </div>
                                        <div v-else>
                                            <div v-if="element.text && element.text.length > 0">
                                                {{ element.text }}
                                            </div>
                                            <div v-else @click.prevent="edit_section_id = element.id">
                                                Personnalisez le texte
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="3" class="text-right pr-5">
                                        <a @click.prevent="edit_section_id = element.id" class="mr-2">
                                            <v-icon>mdi-circle-edit-outline</v-icon>
                                        </a>
                                        <template v-if="loadingDeleteElement === element.id">
                                            <v-progress-circular
                                                indeterminate
                                                color="red"
                                                size="20"
                                                width="3"
                                            ></v-progress-circular>
                                        </template>
                                        <a @click.prevent="confirmDeleteElement(element)" v-else>
                                            <v-icon color="red">mdi-minus-circle-outline</v-icon>
                                        </a>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else-if="element.type === 'image'" class="ifthquestions-line rounded pa-3 grey lighten-3">
                                <v-row>
                                    <v-col cols="1" class="pl-4">
                                        <span class="handle">
                                            <v-icon>mdi-arrow-all</v-icon>
                                        </span>
                                    </v-col>
                                    <v-col cols="2">
                                        IMAGE
                                    </v-col>
                                    <v-col class="text-center">
                                        <div v-if="edit_section_id === element.id">
                                            <v-form @submit.prevent="uploadFormelementImage(element)">
                                                <v-row >
                                                    <v-col cols="8">
                                                        <v-file-input
                                                            accept="image/*"
                                                            label="Image"
                                                            @change="handleFileUpload"
                                                        ></v-file-input>
                                                    </v-col>
                                                    <v-col class="text-left pt-4 pl-0">
                                                        <v-btn small @click.prevent="uploadFormelementImage(element)" :loading="loadingPic">Valider</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </div>
                                        <div v-else>
                                            <div v-if="element.imageurl && element.imageurl.length > 0">
                                                <img :src="element.imageurl" class="img-fluid">
                                            </div>
                                            <div v-else @click.prevent="edit_section_id = element.id">
                                                Choisir une image
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="3" class="text-right pr-5">
                                        <a @click.prevent="edit_section_id = element.id" class="mr-2" v-if="!element.imageurl || element.imageurl.length == 0">
                                            <v-icon>mdi-circle-edit-outline</v-icon>
                                        </a>
                                        <template v-if="loadingDeleteElement === element.id">
                                            <v-progress-circular
                                                indeterminate
                                                color="red"
                                                size="20"
                                                width="3"
                                            ></v-progress-circular>
                                        </template>
                                        <a @click.prevent="confirmDeleteElement(element)" v-else>
                                            <v-icon color="red">mdi-minus-circle-outline</v-icon>
                                        </a>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </transition-group>
                </draggable>
            </div>
            <div v-else class="pa-5 pb-0">
                <em>Vous n'avez pas encore sélectionné de questions</em>
            </div>

            <div class="mt-4 pa-5">
                <template v-if="loadingAddFormelement">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </template>
                <template v-else>
                    <a href="#" class="mr-4" small @click.prevent="addSection('section')">
                        <v-icon color="blue" class="mr-1">mdi-plus-circle-outline</v-icon>Section
                    </a>
                    <a href="#" class="mr-4" small @click.prevent="addSection('text')" style="color:#666">
                        <v-icon color="darkgrey" class="mr-1">mdi-plus-circle-outline</v-icon>Texte libre
                    </a>
                    <a href="#" small @click.prevent="addSection('image')" style="color:#666">
                        <v-icon color="darkgrey" class="mr-1">mdi-plus-circle-outline</v-icon>Image
                    </a>
                </template>
            </div>
        </v-navigation-drawer>


        <v-navigation-drawer
            v-model="panel_model"
            absolute
            temporary
            right
            style="width:90%;max-width:700px"
            >
            <div v-if="form" class="pa-5">
                <h2 class="mb-4 font-weight-normal">Utiliser comme modèle</h2>
                <v-form @submit.prevent="updateFormmodel">
                    <v-checkbox
                        v-model="form.ismodel"
                        label="Définir ce questionnaire comme modèle"
                    ></v-checkbox>
                    <v-select
                        v-model="formmodel_companies"
                        :items="companies"
                        item-text="name"
                        item-value="id"
                        label="Choisissez les entreprises y ayant accès"
                        multiple
                        chips
                        persistent-hint
                    ></v-select>
                    <v-btn color="primary" @click.prevent="updateFormmodel">Valider</v-btn>
                </v-form>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    data() {
        return {
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            loadingCategories: false,
            loadingQuestions: false,
            categories: [],
            selected_category: false,
            questions: [],
            totalQuestions: 0,
            options: {
                page: 1,
                itemsPerPage: 20,
                sortBy: ['id'],
                sortDesc: [true],
            },
            headers: [
                { text: 'N°', value: 'id', sortable: true, },
                { text: 'Intitulé', value: 'title', sortable: true, },
                { text: 'Actions', value: 'actions', sortable: false, align:'center' }
            ],

            form: {
                title: '',
                description: '',
                formelements: [],
                ismodel: false,
                enablelisting: false
            },
            panel_order_questions: false,
            loadingAddQuestion: false,
            loadingAddFormelement: false,

            edit_section_id: false,
            loadingEditFormelement: false,
            modal_edit: false,
            error_save_form: false,
            loadingSave: false,
            loadingDeleteElement: false,

            panel_model: false,
            loadingCompanies: false,
            companies: [],
            formmodel_companies: [],
            loadingSaveFormmodel: false,
            error_save_formmodel: false,

            modal_delete: false,
            element_to_delete: false,
            loadingDeleteBtn: false,

            pic: false,
            loadingPic: false,
            errorsPic: false,
        }
    },
    created() {
        this.getForm();
        this.getCategories();
    },
     methods: {
        getForm() {            
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/forms/' + this.$route.params.id )
                .then(response => {
                    this.form = response.data.data;
                    if( this.form.formmodels ) {
                        this.form.formmodels.forEach((formmodel) => {
                            this.formmodel_companies.push(formmodel.company.id);
                        });
                    }
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getCategories() {            
            var api_url = process.env.VUE_APP_API + '/categories';
            this.loadingCategories = true;
            this.loadingQuestions = true;
            this.$http
                .get(api_url)
                .then(response => {
                    this.categories = response.data.data;
                    if( this.categories && this.categories[0] ) {
                        this.selected_category = this.categories[0];
                        this.getQuestions();
                    }
                    this.loadingCategories = false;
                }).catch( () => {
                    this.loadingCategories = false;
                });
        },
        getQuestions() {
            if( this.selected_category && this.selected_category.id ) {
                // build api url
                var api_url = process.env.VUE_APP_API + '/categories/' + this.selected_category.id + '/questions?page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage;

                if (this.options.sortBy.length === 1) {
                    api_url = api_url + '&sortBy=' + this.options.sortBy[0];
                }

                if(this.options.sortDesc.length === 1) {
                    if( this.options.sortDesc[0] ) {
                        api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=DESC';
                    } else {
                        api_url = api_url + '&sortBy=' + this.options.sortBy[0] + '&sortOrder=ASC';
                    }
                }

                this.loadingQuestions = true;
                this.$http
                    .get(api_url)
                    .then(response => {
                        this.questions = response.data.data;
                        this.totalQuestions = response.data.meta.total;
                        this.options.page = response.data.meta.current_page;
                        this.options.itemsPerPage = response.data.meta.per_page;
                        this.loadingQuestions = false;
                    }).catch( () => {
                        this.loadingQuestions = false;
                    });
            }
        },
        selectCategory(category) {
            this.selected_category = category;
            // reset options
            this.options.page = 1;
            this.getQuestions();
        },
        selectQuestion(q) {
            // loading
            this.loadingAddQuestion = q.id;
            // add to form (backend)
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/addFormelement', {
                    type: 'question',
                    question: q.id
                })
                .then( (response) => {
                    this.loadingAddQuestion = false;
                    this.form.formelements.push(response.data.formelement);
                    this.snackbarText = "Question ajoutée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // todo display error
                    this.loadingAddQuestion = false;
                });
        },
        addSection(formelement_type) {
            // loading
            this.loadingAddFormelement = true;
            // add to form (backend)
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/addFormelement', {
                    type: formelement_type
                })
                .then( (response) => {
                    this.loadingAddFormelement = false;
                    this.form.formelements.push(response.data.formelement);
                    this.snackbarText = "Élement ajouté au formulaire";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // todo display error
                    this.loadingAddFormelement = false;
                });
        },
        confirmDeleteElement(formelement) {
            this.element_to_delete = formelement;
            this.modal_delete = true;
        },
        deleteFormelement() {
            this.loadingDeleteElement = this.element_to_delete.id;
            this.loadingDeleteBtn = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/forms/' + this.form.id + '/formelements/' + this.element_to_delete.id)
                .then( () => {
                    this.loadingDeleteElement = false;
                    this.loadingDeleteBtn = false;
                    this.element_to_delete = false;
                    this.modal_delete = false;
                    // reload form
                    this.getForm();
                    this.snackbarText = "Element supprimé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // todo display error
                    this.loadingDeleteElement = false;
                    this.loadingDeleteBtn = false;
                });
        },
        saveOrder() {
            // build array to send
            var array_elements = [];
            this.form.formelements.forEach((e) => {
                array_elements.push(e.id);
            });

            // send positions to server
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/formelements/order', {
                    elements: array_elements
                })
                .then( () => {
                    // display notification success
                    this.snackbarText = "Ordre des questions enregistré";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // display notification error
                    this.snackbarText = "L'ordre des questions n'a pas pû être enregistré";
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                });
        },
        updateFormelement(element) {
            // loading
            this.loadingEditFormelement = element.id;
            // add to form (backend)
            this.$http
                .put(process.env.VUE_APP_API + '/forms/' + this.form.id + '/formelements/' + element.id, {
                    text: element.text
                })
                .then( () => {
                    this.edit_section_id = false;
                    this.loadingEditFormelement = false;
                    this.snackbarText = "Élement modifié";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // todo display error
                    element.text = '';
                    this.loadingEditFormelement = false;
                });
        },
        handleFileUpload(e) {
            this.pic = e;
        },
        uploadFormelementImage(element) {
            this.loadingPic = true;
            this.errorsPic = null;
            let formData = new FormData();
            formData.append('pic', this.pic);
            this.$http
                .post(process.env.VUE_APP_API + '/forms/' + this.form.id + '/formelements/' + element.id + '/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.loadingPic = false;
                    this.snackbarText = "Image enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    this.edit_section_id = false;
                    this.pic = '';

                    // reload form
                    this.getForm();
                }).catch( error => {
                    this.errorsPic = error.response.data;
                    this.loadingPic = false;
                });
        },
        updateForm() {
            this.loadingSave = true;
            this.error_save_form = false;
            this.$http
                .put(process.env.VUE_APP_API + '/forms/' + this.form.id, {
                    title: this.form.title,
                    description: this.form.description,
                    access: this.form.access,
                    enablelisting: this.form.enablelisting
                })
                .then( () => {
                    this.loadingSave = false;
                    // display success
                    this.snackbarText = "Formulaire mis à jour";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.modal_edit = false;
                }).catch( error => {
                    this.error_save_form = error.response.data;
                    this.loadingSave = false;
                });
        },
        openPanelModel() {
            this.panel_model = true
            this.loadingCompanies = true;
            this.$http
                .get(process.env.VUE_APP_API + '/admin/companies?all=1' )
                .then(response => {
                    this.companies = response.data.data;
                    this.loadingCompanies = false;
                }).catch( () => {
                    this.loadingCompanies = false;
                });
        },
        updateFormmodel() {
            this.loadingSaveFormmodel = true;
            this.error_save_formmodel = false;
            this.$http
                .put(process.env.VUE_APP_API + '/admin/forms/' + this.form.id + '/formmodels', {
                    ismodel: this.form.ismodel,
                    companies: this.formmodel_companies
                })
                .then( () => {
                    this.loadingSaveFormmodel = false;
                    // if not model, empty it
                    if( ! this.form.ismodel ) {
                        this.formmodel_companies = [];
                    }
                    // display success
                    this.snackbarText = "Formulaire mis à jour";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.panel_model = false;
                }).catch( error => {
                    this.error_save_formmodel = error.response.data;
                    this.loadingSaveFormmodel = false;
                });
        }
    },
     watch: {
        options: {
            handler () {
                this.getQuestions();
            },
            deep: true,
        },
    },
    components: {
        draggable
    }
}
</script>
